import { useState, useEffect } from "react";
import Home from "../../Layout/Home";
import TransactionChart from "./Components/TransactionChart";
import DashboardStatsGrid from "./Components/DashboardStatsGrid";
import BuyerProfilePieChart from "./Components/BuyerProfilePieChart";
import ListTop from "./Components/ListTop";
import PopularProducts from "./Components/PopularProducts";
import Select from "react-select";

function DashBoard() {
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    fetch(
      "https://sysdemo.byma-ve.com/BackendApiRest/Administracion/Cliente/obtener_clientes.php"
    )
      .then((response) => response.json())
      // .then((data) => setClientes(data));
      .then((data) => {
        const transformedClientes = [
          { value: "", label: "TODOS" },
          { value: "PUNTOVENTAS", label: "CLIENTES DE PIE" },
          ...data.map((cliente) => ({
            value: cliente.id,
            label: cliente.razon_social_cliente,
          })),
        ];
        setClientes(transformedClientes);
      });
  }, []);

  const [isDisabledArea, setIsDisabledArea] = useState(false);

  const [idCliente, setIdCliente] = useState("");
  const handleSelectCliente = (selectedCliente) => {
    if (selectedCliente) {
      setIdCliente(selectedCliente.id);
      setAreas([]);
      setIdArea("");

      if (selectedCliente.id == "PUNTOVENTAS") {
        setIsDisabledArea(true);
      } else {
        setIsDisabledArea(false);
      }
    } else {
      setIdCliente("");
      setAreas([]);
      setIdArea("");
      setIsDisabledArea(false);
    }
  };

  const [areas, setAreas] = useState([]);

  useEffect(() => {
    if (idCliente) {
      fetch(
        `https://sysdemo.byma-ve.com/BackendApiRest/Administracion/Area/obtenerAreasTarifario.php?id_cliente=${idCliente}`
      )
        .then((response) => response.json())
        .then((data) => {
          const transformedAreas = [
            { value: "TODOS", label: "ELEGIR TODAS LAS AREAS" },
            ...data.map((area) => ({
              value: area.id,
              label: area.nombre_area,
            })),
          ];
          setAreas(transformedAreas);
        });
    }
  }, [idCliente]);

  const [idArea, setIdArea] = useState("");
  const handleSelectArea = (selectedArea) => {
    if (selectedArea) {
      setIdArea(selectedArea.id);
    }
  };

  const [years, setYears] = useState([]);

  useEffect(() => {
    fetch(
      `https://sysdemo.byma-ve.com/BackendApiRest/Dashboard/selectYear.php?id_cliente=${idCliente}&id_area=${idArea}`
    )
      .then((response) => response.json())
      .then((data) => {
        const transformedAnios = [
          { value: "", label: "TODOS" },
          ...data.map((anio) => ({
            value: anio.nombre_year,
            label: anio.nombre_year,
          })),
        ];
        setYears(transformedAnios);
      });
  }, [idCliente, idArea]);

  const [datosEmpresa, setDatosEmpresa] = useState([]);

  useEffect(() => {
    fetch(`https://sysdemo.byma-ve.com/BackendApiRest/Configuracion/obtenerEmpresa.php`)
      .then((response) => response.json())
      .then((data) => {
        setDatosEmpresa(data);
      });
  }, []);

  const [idYear, setIdYear] = useState("");
  const handleSelectYear = (selectedYear) => {
    if (selectedYear) {
      setIdYear(selectedYear.nombre_year);
    } else {
      setIdYear("");
    }
  };

  const [isImageShown, setIsImageShown] = useState(false);
  useEffect(() => {
    if (!isImageShown) {
      setIsImageShown(true);
    }
  }, [isImageShown]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      maxHeight: "30px",
      minHeight: "37px",
      height: "10px",
      fontSize: "16px",
      borderRadius: "10px",
      backgroundColor: "transparent",
      border: "none",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "5px",
      fontSize: "14px",
      margin: "6px 0",
      padding: "8px 0px",
    }),
    option: (provided) => ({
      ...provided,
      borderRadius: "5px",
      padding: "4px 12px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 8px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      //display: "none", Oculta el indicador
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none", // Oculta la barrita al lado del indicador
    }),
  };

  return (
    <>
      <Home
        children1={<></>}
        children2={
          <>
            <div className="flex gap-4 mb-4 items-center">
              <div className="flex">
                {" "}
                {/* <SearchDashboard
                  clientes={clientes}
                  handleSelectCliente={handleSelectCliente}
                /> */}
                <Select
                  options={clientes}
                  styles={customStyles}
                  onChange={(selectedOption) =>
                    handleSelectCliente({
                      id: selectedOption.value,
                      razon_social_cliente: selectedOption.label,
                    })
                  }
                  placeholder="Elegir Cliente"
                  className="w-[320px] h-[38px] bg-white rounded-lg"
                />
              </div>
              <div className="flex ">
                <Select
                  options={areas}
                  styles={customStyles}
                  onChange={(selectedOption) =>
                    handleSelectArea({
                      id: selectedOption.value,
                      nombre_area: selectedOption.label,
                    })
                  }
                  value={
                    idArea
                      ? areas.find((option) => option.value === idArea)
                      : ""
                  }
                  placeholder="Elegir Area"
                  className="w-[250px] h-[38px] bg-white rounded-lg"
                  isDisabled={isDisabledArea}
                />

                {/* <SearchArea
                  areas={areas}
                  idCliente={idCliente}
                  handleSelectArea={handleSelectArea}
                /> */}
              </div>
              <div className="flex ">
                <Select
                  options={years}
                  styles={customStyles}
                  onChange={(selectedOption) =>
                    handleSelectYear({
                      nombre_year: selectedOption.value,
                      nombre: selectedOption.label,
                    })
                  }
                  placeholder="Elegir Año"
                  className="w-[150px] h-[38px] bg-white rounded-lg"
                />
                {/* <SearchDate years={years} handleSelectYear={handleSelectYear} /> */}
              </div>
            </div>
            <div>
              <DashboardStatsGrid
                logoEmpresa={datosEmpresa.logo_oscuro_empresa || ""}
                idArea={idArea}
                idCliente={idCliente}
                idYear={idYear}
              />
            </div>
            <div className="flex gap-4">
              <TransactionChart
                idArea={idArea}
                idCliente={idCliente}
                idYear={idYear}
              />
              <BuyerProfilePieChart
                idArea={idArea}
                idCliente={idCliente}
                idYear={idYear}
              />
            </div>
            <div className="flex gap-4 mt-4 max-h-[325px] ">
              <ListTop idArea={idArea} idCliente={idCliente} idYear={idYear} />
              <PopularProducts />
            </div>
          </>
        }
      />
    </>
  );
}

export default DashBoard;
