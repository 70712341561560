import { useEffect, useState } from "react";
import { IconoBasura, IconoLapiz, IconoArea } from "../../Iconos/Iconos-NavBar";
import Home from "../../Layout/Home";
import Pagination from "../Administración/Usuario/Components/PaginacionAdmin";
import Select from "react-select";
import Logo from "../../Static/Img_Pred/LogoOscuro.webp";
import Swal from "sweetalert2";
import { Clientes } from "./Modals/Clientes";

const Mantenimiento = () => {
  const [Titulo, setTitulo] = useState("Insertar Titulo");
  const [Mensaje, setMensaje] = useState("Insertar Mensaje ");
  const [mostrarTextos, setMostrarTextos] = useState(false); // Estado para manejar la visibilidad de los textos
  const [idAreas, setIdAreas] = useState("");
  const [seleccionarTodos, setSeleccionarTodos] = useState(false);
  const [areasSeleccionadas, setAreasSeleccionadas] = useState({});

  const customStyles = {
    control: (provided) => ({
      ...provided,
      maxHeight: "30px",
      minHeight: "37px",
      height: "10px",
      fontSize: "16px",
      borderRadius: "10px",
      backgroundColor: "transparent",
      border: "none",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "5px",
      fontSize: "14px",
      margin: "6px 0",
      padding: "8px 0px",
    }),
    option: (provided) => ({
      ...provided,
      borderRadius: "5px",
      padding: "4px 12px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 8px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      //display: "none", Oculta el indicador
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none", // Oculta la barrita al lado del indicador
    }),
  };

  const [alertas, setAlertas] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  const cargarTabla = async () => {
    try {
      const response = await fetch(
        "https://sysdemo.byma-ve.com/BackendApiRest/AlertasClientes/obtenerAlertas.php"
      );
      const data = await response.json();
      setAlertas(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    cargarTabla();
  }, []);

  const filteredAlertas = alertas.filter((alerta) =>
    Object.values(alerta).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredAlertas.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredAlertas.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const totalItems = filteredAlertas.length;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [modalClientes, setModalClientes] = useState(false);

  // Funcion para mostrar el Modal de Clientes
  const mostrarModalClientes = () => {
    setModalClientes(true);
  };

  const handleTituloChange = (e) => {
    const value = e.target.value;
    setTitulo(value || "Título de ejemplo"); // Si está vacío, usa el valor por defecto
    setMostrarTextos(true); // Mostrar los textos ocultos cuando el alertas empieza a escribir
  };

  // Maneja cambios en el textarea del Mensaje
  const handleMensajeChange = (e) => {
    const value = e.target.value;
    setMensaje(value || "Mensaje de ejemplo por defecto"); // Si está vacío, usa el valor por defecto
    setMostrarTextos(true); // Mostrar los textos ocultos cuando el alertas empieza a escribir
  };

  const [datosEmpresa, setDatosEmpresa] = useState({});
  useEffect(() => {
    cargarImagenEmpresa();
    cargarClientes();
  }, []);

  const cargarImagenEmpresa = async () => {
    try {
      const response = await fetch(
        "https://sysdemo.byma-ve.com/BackendApiRest/Configuracion/obtenerEmpresa.php"
      );
      const data = await response.json();
      setDatosEmpresa(data);
    } catch (error) {
      console.error("Error fetching empresa:", error);
    }
  };

  const [clientes, setClientes] = useState([]);

  const cargarClientes = async () => {
    try {
      const response = await fetch(
        "https://sysdemo.byma-ve.com/BackendApiRest/Administracion/Cliente/obtener_clientes.php"
      );
      const data = await response.json();

      const transformedClientes = [
        ...data.map((cliente) => ({
          value: cliente.id,
          label: cliente.razon_social_cliente,
        })),
      ];
      setClientes(transformedClientes);
    } catch (error) {
      console.error("Error fetching empresa:", error);
    }
  };

  const [idCliente, setIdCliente] = useState("");
  const handleSelectCliente = (selectedCliente) => {
    if (selectedCliente) {
      setIdCliente(selectedCliente.id);
    } else {
      setIdCliente("");
    }
  };

  const [formulario, setFormulario] = useState({
    id: null,
    id_cliente: null,
    id_areas: null,
    nombres_alertas: null,
    fecha_vigencia_alerta: null,
    hora_vigencia_alerta: null,
    titulo_alerta: null,
    mensaje_alerta: null,
    enviar_todos: null,
    id_creador_alerta: localStorage.getItem("id_usuario"),
  });

  useEffect(() => {
    console.log(formulario);
  }, [formulario]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormulario((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    setFormulario((prevValues) => ({ ...prevValues, [name]: checked }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const confirmResult = await Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Deseas guardar estos datos?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, guardar",
      cancelButtonText: "Cancelar",
    });

    if (confirmResult.isConfirmed) {
      Swal.fire({
        allowOutsideClick: false,
        showConfirmButton: false,
        background: "transparent",
        html: `
        <div class="papapa"> 
          <div class="loader1"> 
          <h1 class="guardado" >Guardando...</h1>
          </div>
        
          <div class="loader2">
            <div class="justify-content-center jimu-primary-loading"></div>
          </div>
        </div>
      `,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const response = await fetch(
          "https://sysdemo.byma-ve.com/BackendApiRest/AlertasClientes/guardarAlertaCliente.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...formulario }),
          }
        );

        const responseData = await response.json();
        if (responseData.success) {
          Swal.fire({
            icon: "success",
            title: responseData.mensaje,
          });
          formularioVacio();
          habilitarBotones();
          setIdCliente("");
          setAreasSeleccionadas({});
          setSeleccionarTodos(false);
        } else {
          Swal.fire({
            icon: "error",
            title: responseData.mensaje,
          });
        }
        cargarTabla();
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error en la solicitud de Red",
        });
      }
    }
  };

  const formularioVacio = () => {
    setFormulario({
      id: "",
      id_cliente: "",
      id_areas: "",
      nombres_alertas: "",
      fecha_vigencia_alerta: "",
      hora_vigencia_alerta: "",
      titulo_alerta: "",
      mensaje_alerta: "",
      enviar_todos: "",
      id_creador_alerta: localStorage.getItem("id_usuario"),
    });
    setTitulo("Insertar Titulo");
    setMensaje("Insertar Mensaje");
  };

  const handleEdit = (alerta) => {
    setFormulario({
      id: alerta.id,
      id_cliente: alerta.id_cliente,
      id_areas: alerta.id_areas,
      nombres_alertas: alerta.nombres_alertas,
      fecha_vigencia_alerta: alerta.fecha_vigencia_alerta,
      hora_vigencia_alerta: alerta.hora_vigencia_alerta,
      titulo_alerta: alerta.titulo_alerta,
      mensaje_alerta: alerta.mensaje_alerta,
      enviar_todos: alerta.id_cliente === null ? true : alerta.enviar_todos,
      id_creador_alerta: localStorage.getItem("id_usuario"),
    });
    setTitulo(alerta.titulo_alerta);
    setMensaje(alerta.mensaje_alerta);
    setIdCliente(alerta.id_cliente);
    deshabilitarBotones();
  };

  const [deshabilitar, setDeshabilitar] = useState({
    id_cliente: false,
  });

  const deshabilitarBotones = () => {
    setDeshabilitar({
      id_cliente: true,
    });
  };

  const habilitarBotones = () => {
    setDeshabilitar({
      id_cliente: false,
    });
  };

  const eliminarAlerta = async (id) => {
    try {
      const response = await fetch(
        `https://sysdemo.byma-ve.com/BackendApiRest/AlertasClientes/eliminarAlerta.php?id_alerta=${id}`,
        {
          method: "GET",
        }
      );

      const responseData = await response.json();
      if (responseData.success) {
        Swal.fire({
          icon: "success",
          title: responseData.mensaje,
        });
        formularioVacio();
        habilitarBotones();
        setIdCliente("");
      } else {
        console.error("Error al eliminar la alerta");
        Swal.fire({
          icon: "error",
          title: responseData.mensaje,
        });
      }
    } catch (error) {
      console.error("Error en la solicitud de eliminación:", error);
      Swal.fire({
        icon: "error",
        title: "Error en la solicitud de eliminación",
      });
      console.log(error);
    }
    cargarTabla();
  };

  const handleEliminarAlerta = (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esto",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminarlo",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        eliminarAlerta(id);
      }
    });
  };
  return (
    <Home
      children1={
        <>
          {" "}
          <Clientes
            setIdAreas={setIdAreas}
            idAreas={idAreas}
            setFormulario={setFormulario}
            modalClientes={modalClientes}
            setModalClientes={setModalClientes}
            idCliente={idCliente}
            setAreasSeleccionadas={setAreasSeleccionadas}
            setSeleccionarTodos={setSeleccionarTodos}
            seleccionarTodos={seleccionarTodos}
            areasSeleccionadas={areasSeleccionadas}
          />{" "}
        </>
      }
      children2={
        <>
          {" "}
          <div className="  relative  overflow-auto     ScrollTable rounded-2xl h-[75vh] ">
            <div className="grid grid-cols-[2fr,1.2fr] gap-x-4">
              <div className="PROGRAMACIO DE ALERTAS ">
                <div className="bg-[#fff] rounded-xl">
                  <div className="py-4 font-bold  text-xl rounded-t-xl bg-blue-400 text-white px-4 ">
                    <h1>Programacion de alertas </h1>
                  </div>
                  <div className="h-[0.1px] border-b mx-4 border-gray-300 shadow mb-4"></div>
                  <div className="grid grid-cols-[1fr,.95fr] gap-x-4  px-4 rounded-xl  text-gray-700">
                    <div className="h-auto overflow-auto   rounded-xl  text-gray-700">
                      <div className=" w-full justify-between space-y-2 ps-2">
                        <Select
                          styles={customStyles}
                          options={clientes}
                          onChange={(selectedOption) => {
                            handleSelectCliente({
                              id: selectedOption.value,
                              razon_social_cliente: selectedOption.label,
                            });
                            handleChange({
                              target: {
                                name: "id_cliente",
                                value: selectedOption.value,
                              },
                            });
                          }}
                          value={
                            formulario.id_cliente
                              ? clientes.find(
                                  (option) =>
                                    option.value === formulario.id_cliente
                                )
                              : null
                          }
                          id="id_cliente"
                          name="id_cliente"
                          isDisabled={deshabilitar.id_cliente}
                          placeholder="Elegir cliente"
                          className=" h-[38px] border rounded-lg bg-white   text-start "
                        />
                      </div>{" "}
                      <div className="ESCRIBIR CORREO mt-4">
                        <div className=" top-0 right-0  justify-end flex mt-6 ps-2">
                          <button
                            disabled={!idCliente || deshabilitar.id_cliente}
                            onClick={mostrarModalClientes}
                            className={
                              `text-blue-400 text-4xl   ` +
                              (idCliente ? "" : "cursor-not-allowed")
                            }
                          >
                            <IconoArea />
                          </button>{" "}
                        </div>
                      </div>
                      <div className="ALERTAS mt-7 flex justify-between">
                        <div className="text-4xl ">
                          <label className="relative inline-flex items-center w-full     rounded p-3 min-w-full">
                            <input
                              type="checkbox"
                              className="sr-only peer "
                              id="enviar_todos"
                              name="enviar_todos"
                              onChange={handleChangeCheckbox}
                              checked={formulario.enviar_todos}
                            />
                            <div className="w-10 h-6 rounded-full bg-gray-300 cursor-pointer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:absolute after:top-[.875rem] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all border-gray-300 peer-checked:bg-blue-400"></div>
                            <span className="ms-4 text-base font-normal  ">
                              Enviar Todos
                            </span>
                          </label>
                        </div>
                        <button
                          onClick={handleSubmit}
                          className="rounded-lg ButtonAzul h-10 items-center  text-white"
                        >
                          Alertar
                        </button>
                      </div>
                    </div>
                    <div className="h-auto overflow-auto px-2 pt-3 rounded-xl  text-gray-700">
                      {" "}
                      <div className="flex  ">
                        <div className=" text-base font-normal gap-x-4 flex justify-between w-full   text-gray-700  ">
                          <div className="relative z-0 w-full group">
                            <input
                              type="date"
                              name="fecha_vigencia_alerta"
                              id="fecha_vigencia_alerta"
                              className=" block py-[9px] px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              value={formulario.fecha_vigencia_alerta}
                              onChange={handleChange}
                              required
                            />
                            <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                              Fecha de Vigencia *
                            </label>
                          </div>
                          <div className="relative z-0 w-full group">
                            <input
                              type="time"
                              name="hora_vigencia_alerta"
                              id="hora_vigencia_alerta"
                              className=" block py-[9px] px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              value={formulario.hora_vigencia_alerta}
                              onChange={handleChange}
                              required
                            />
                            <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                              Hora de vigencia
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className=" mt-4">
                        <div className="relative z-0 w-full group">
                          <input
                            type="text"
                            name="titulo_alerta"
                            id="titulo_alerta"
                            className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                            onChange={(selectedOption) => {
                              handleChange(selectedOption);
                              handleTituloChange(selectedOption);
                            }}
                            value={formulario.titulo_alerta}
                            onInput={(event) => {
                              const { selectionStart, selectionEnd } =
                                event.target;
                              const upperCasedValue =
                                event.target.value.toUpperCase();
                              event.target.value = upperCasedValue;
                              event.target.setSelectionRange(
                                selectionStart,
                                selectionEnd
                              );
                            }}
                          />
                          <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            Escribir titulo
                          </label>
                        </div>
                      </div>
                      <div className="w-full mt-4">
                        <textarea
                          name="mensaje_alerta"
                          id="mensaje_alerta"
                          maxLength={600}
                          className="text-black p-2 w-full bg-gray-200 min-h-32 overflow-y-auto ScrollTableVertical max-h-32 rounded-md  focus:ring-1 outline-none"
                          onChange={(selectedOption) => {
                            handleChange(selectedOption);
                            handleMensajeChange(selectedOption);
                          }}
                          value={formulario.mensaje_alerta}
                          onInput={(event) => {
                            const { selectionStart, selectionEnd } =
                              event.target;
                            const upperCasedValue =
                              event.target.value.toUpperCase();
                            event.target.value = upperCasedValue;
                            event.target.setSelectionRange(
                              selectionStart,
                              selectionEnd
                            );
                          }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="h-[0.1px] border-b mx-4 border-gray-300 shadow my-4"></div>

                  <div className="TABLA">
                    <div className="mx-7 relative overflow-x-auto ScrollTable min-h-auto max-h-[74vh]  bg-[#fff]   rounded-t-xl ">
                      <table className="  w-[100%] table-fixed  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                        {filteredAlertas.length === 0 ? (
                          <span className="  text-center flex justify-center mt-3 text-base">
                            No se encontraron resultados
                          </span>
                        ) : (
                          <tbody className="  block text-left text-[#535c69] whitespace-nowrap ">
                            <thead className="text-left text-md   sticky top-0 bg-white border-b border-gray-300 text-gray-700 whitespace-nowrap ">
                              <tr className=" border-b bg-white  border-gray-300 ">
                                <th scope="col" className="px-4 py-3 w-1/4 ">
                                  Acciones
                                </th>
                                <th scope="col" className="px-4 py-3 w-1/4 ">
                                  Enviados
                                </th>
                                <th
                                  scope="col"
                                  className="px-4 py-3 w-1/4 text-gray-700"
                                >
                                  Titulo
                                </th>
                                <th
                                  scope="col"
                                  className="px-4 py-3 w-1/4 text-gray-700"
                                >
                                  Fecha de vigencia
                                </th>
                                <th scope="col" className="px-4 py-3 w-1/4 ">
                                  <div className="flex items-center">
                                    Hora de vigencia
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            {filteredAlertas &&
                              currentItems.map((alerta, index) => (
                                <tr
                                  className="border-b bg-white border-gray-300 w-full"
                                  key={index}
                                >
                                  <td className="px-4 py-3 w-1/4">
                                    <div className="flex justify-start text-[15px] gap-x-4 -z-10">
                                      <button
                                        onClick={() => handleEdit(alerta)}
                                        className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] 00 p-[6px]  rounded-lg active:scale-90"
                                      >
                                        <IconoLapiz />
                                        <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                          Editar
                                        </div>
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleEliminarAlerta(alerta.id)
                                        }
                                        className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] p-[6px] rounded-lg active:scale-90"
                                      >
                                        <IconoBasura />
                                        <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                          Eliminar
                                        </div>
                                      </button>
                                    </div>
                                  </td>
                                  <td className="px-4 py-3 w-1/4 ">
                                    {alerta.nombre_cliente}
                                  </td>

                                  <td className="px-4 py-3 w-1/4">
                                    {alerta.titulo_alerta}
                                  </td>
                                  <td className="px-4 py-3 w-1/4">
                                    {alerta.fecha_vigencia_alerta}
                                  </td>
                                  <td className="px-4 py-3 w-1/4">
                                    {alerta.hora_vigencia_alerta}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                    <div className="mx-7 rouded-b-xl">
                      <Pagination
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" ">
                <div className=" PREVISUALIZACION   overflow-auto  bg-[#fff]  rounded-xl  text-gray-700">
                  <div className="rounded-xl  h-[57%]">
                    <div className="py-4 font-bold rounded-t-xl  text-xl bg-blue-400 text-white px-4 ">
                      <h1>Previsualización de anuncio</h1>
                    </div>
                    <div className="p-4 ">
                      <div className="border  max-h-full h-[540px] shadow-md flex flex-col justify-between">
                        <div className="space-y-4 ">
                          <div className="uppercase italic  ">
                            <div className="flex  justify-center place-items-baseline  gap-x-2">
                              <h1 className="text-4xl font-bold text-[#6C6D70] ">
                                !Siempre
                              </h1>
                              <h1 className="text-3xl font-semibold  text-[#6C6D70]">
                                {" "}
                                en contacto
                              </h1>
                            </div>
                            <div className="flex justify-center place-items-baseline">
                              <h1 className="text-6xl font-bold text-blue-500">
                                CONTIGO!{" "}
                              </h1>
                            </div>
                          </div>
                          <div className="flex  justify-center  italic mx-4 text-center">
                            <p className="text-[20px] font-semibold text-[#6C6D70]">
                              {/* Retraso en los Envíos Debido a Marchas de
                            Transportistas */}
                              {Titulo}
                            </p>
                          </div>
                          <div className="flex flex-col justify-center  italic mx-4  text-center space-y-2 uppercase">
                            {mostrarTextos && (
                              <p className="text-[15px] font-semibold text-[#6C6D70]">
                                Estimados clientes,
                              </p>
                            )}
                            <p className="text-[15px] font-semibold text-[#6C6D70]">
                              {Mensaje}
                            </p>
                            {mostrarTextos && (
                              <p className="text-[15px] font-semibold text-[#6C6D70]">
                                Gracias por su comprensión.
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="w-full flex justify-between">
                          <p></p>
                          {datosEmpresa.logo_oscuro_empresa && (
                            <img
                              src={datosEmpresa?.logo_oscuro_empresa || Logo}
                              className="w-[150px] "
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    ></Home>
  );
};

export default Mantenimiento;
