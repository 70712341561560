export const EncabTablaCourier = [
  "Ubigeo",
  "Zona",
  "Departamento",
  "Provincia",
  "Distrito",
  "KG",
  "KG Adicional",
  "T.Min Entrega",
  "T.Max Entrega",
];
