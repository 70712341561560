// src/Context/EmpresaContext.js
import { createContext, useState, useEffect } from "react";

export const EmpresaContext = createContext();

export const EmpresaProvider = ({ children }) => {
  const [datosEmpresa, setDatosEmpresa] = useState({});

  const cargarImagenEmpresa = async () => {
    try {
      const response = await fetch(
        "https://sysdemo.byma-ve.com/BackendApiRest/Configuracion/obtenerEmpresa.php"
      );
      const data = await response.json();
      setDatosEmpresa(data);
    } catch (error) {
      console.error("Error fetching empresa:", error);
    }
  };

  const actualizarEmpresa = (nuevosDatos) => {
    setDatosEmpresa((prevDatos) => ({ ...prevDatos, ...nuevosDatos }));
  };

  useEffect(() => {
    cargarImagenEmpresa();
  }, []);

  return (
    <EmpresaContext.Provider
      value={{ datosEmpresa, actualizarEmpresa, cargarImagenEmpresa }}
    >
      {children}
    </EmpresaContext.Provider>
  );
};
