import   { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
const ConfigPDFCotizacion = ({ modalConfiguracion, setModalConfiguracion }) => {
  const [datos, setDatos] = useState("");
  const [clientes, setClientes] = useState("");

  useEffect(() => {
    fetch(
      "https://sysdemo.byma-ve.com/BackendApiRest/Administracion/Cliente/obtenerClientesTarifarios.php"
    )
      .then((response) => response.json())
      .then((data) => {
        const formattedOptions = data.map((option) => ({
          value: option.id,
          label: option.razon_social_cliente,
        }));
        setClientes(formattedOptions);
      })
      .catch((error) => {
        console.error("Error al obtener las opciones:", error);
      });
  }, []);

  const inicializarFormulario = () => ({
    id: 1,
    id_cliente_pdf: "",
    atencion_pdf: "",
    asunto_pdf: "",
    mensaje_pdf: "",
    servicio_incluido_1_pdf: "",
    servicio_incluido_2_pdf: "",
    servicio_incluido_3_pdf: "",
    servicio_incluido_4_pdf: "",
    servicio_incluido_5_pdf: "",
  });

  const [formulario, setFormulario] = useState(inicializarFormulario);
  const [idCliente, setIdCliente] = useState(inicializarFormulario);

  useEffect(() => {
    setFormulario({
      id: datos?.id || formulario.id,
      atencion_pdf: datos?.atencion_pdf || formulario.atencion_pdf,
      asunto_pdf: datos?.asunto_pdf || formulario.asunto_pdf,
      mensaje_pdf: datos?.mensaje_pdf || formulario.mensaje_pdf,
      servicio_incluido_1_pdf:
        datos?.servicio_incluido_1_pdf || formulario.servicio_incluido_1_pdf,
      servicio_incluido_2_pdf:
        datos?.servicio_incluido_2_pdf || formulario.servicio_incluido_2_pdf,
      servicio_incluido_3_pdf:
        datos?.servicio_incluido_3_pdf || formulario.servicio_incluido_3_pdf,
      servicio_incluido_4_pdf:
        datos?.servicio_incluido_4_pdf || formulario.servicio_incluido_4_pdf,
      servicio_incluido_5_pdf:
        datos?.servicio_incluido_5_pdf || formulario.servicio_incluido_5_pdf,
      id_cliente_pdf: idCliente,
    });
  }, [datos]);

  const [resetForm, setResetForm] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormulario((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const CerraModal = () => {
    setModalConfiguracion(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (const key in formulario) {
      formData.append(key, formulario[key]);
    }
    try {
      const response = await fetch(
        "https://sysdemo.byma-ve.com/BackendApiRest/Comercial/Cotizacion/guardarDatosPdf.php",
        {
          method: "POST",
          body: formData,
        }
      );

      const responseData = await response.json();
      if (responseData.success) {
        Swal.fire({
          icon: "success",
          title: responseData.message,
        });
        CerraModal();
      } else {
        Swal.fire({
          icon: "error",
          title: responseData.message,
        });
      }
    } catch (error) {
      console.error("Error al realizar la solicitud:", error);
      Swal.fire({
        icon: "error",
        title: "Error de red",
        text: "Hubo un problema de red al intentar actualizar los datos.",
      });
    }
  };

  const customStyles = {
    control: (provided ) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "none",
      fontSize: "14px",
      borderRadius: "0px",
      height: "16px",
      borderBottom: "2px solid #9ca3af",
      boxShadow: "none",
      "&:active": {
        borderColor: "#0389fb ",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "10px 0px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "45px",
    }),

    menu: (provided) => ({
      ...provided,
      marginTop: "5px",
      borderRadius: "4px",
      boxShadow:
        "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    }),
    option: (provided ) => ({
      ...provided,
      fontSize: "12px",
      borderRadius: "5px",
      padding: "8px 6px",
    }),
  };

  const handleSelectChange = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : "";
    setIdCliente(value);
    if (value) {
      fetch(
        `https://sysdemo.byma-ve.com/BackendApiRest/Comercial/Cotizacion/obtenerContenidoPdf.php?id_cliente=${value}`
      )
        .then((response) => response.json())
        .then((data) => {
          setFormulario(inicializarFormulario);
          setDatos(data);
          console.log(data);
        })
        .catch((error) => console.error("Error fetching contenido:", error));
    }
  };

  return (
    <>
      <div
        className={`side-panel-container ${
          modalConfiguracion ? "visible" : "invisible"
        } fixed pointer-events-auto left-0 top-0 right-0 h-full bg-[rgba(0,0,0,0.4)] z-[11]`}
      >
        <div
          className={`side-panel-cont-container ${
            modalConfiguracion ? "translate-x-[0%]" : "translate-x-[100%]"
          } w-[500px] h-full block absolute top-0 right-0 bottom-0 bg-slate-100 transition-transform duration-1000 z-[12]`}
        >
          <div className="side-panel-content-container block absolute top-0 right-0 bottom-0 left-0 p-0 ">
            <div className="side-panel-iframe relative w-full h-full overflow-y-auto ScrollTableVertical ">
              <form key={resetForm} onSubmit={handleSubmit}>
                <div className="side-panel  p-[0_15px_21px_21px] h-full w-auto m-0 overflow-y-auto  ScrollTableVertical ">
                  <div className="side-cont-titulo p-[12px_0] text-[25px] font-medium text-slate-900 opacity-80"></div>
                  <div className="SERVICIOSINCLUIDOS space-y-6">
                    <div className="bg-blue-400 w-ful rounded-md px-2 text-white py-1">
                      <h1>Contenido de PDF cotizacion</h1>
                    </div>
                    <div className="gap-x-4 px-2">
                      <div className="relative  w-full mb-4 group">
                        <Select
                          name="id_cliente_pdf"
                          id="id_cliente_pdf"
                          options={clientes}
                          styles={customStyles}
                          onChange={handleSelectChange}
                          placeholder="Elegir Cliente"
                          className="block py-2.5 px-0 w-full text-sm"
                          required
                        />
                        <label className="upp peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-5 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                          Filtrar Cliente *
                        </label>
                      </div>
                      <div className="relative z-0 w-full  group">
                        <input
                          type="text"
                          name="atencion_pdf"
                          id="atencion_pdf"
                          value={formulario.atencion_pdf}
                          className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          onChange={handleChange}
                          onInput={(event) => {
                            const { selectionStart, selectionEnd } =
                              event.target;
                            const upperCasedValue =
                              event.target.value.toUpperCase();
                            event.target.value = upperCasedValue;
                            event.target.setSelectionRange(
                              selectionStart,
                              selectionEnd
                            );
                          }}
                          maxLength={100}
                          required
                        />
                        <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                          Atencion
                        </label>
                      </div>
                    </div>
                    <div className="gap-x-4 px-2">
                      <div className="relative z-0 w-full  group">
                        <input
                          type="text"
                          name="asunto_pdf"
                          id="asunto_pdf"
                          value={formulario.asunto_pdf}
                          className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          onChange={handleChange}
                          onInput={(event) => {
                            const { selectionStart, selectionEnd } =
                              event.target;
                            const upperCasedValue =
                              event.target.value.toUpperCase();
                            event.target.value = upperCasedValue;
                            event.target.setSelectionRange(
                              selectionStart,
                              selectionEnd
                            );
                          }}
                          maxLength={100}
                          required
                        />
                        <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                          Asunto
                        </label>
                      </div>
                    </div>
                    <div className="gap-x-4 px-2">
                      <div className="relative z-0 w-full  group">
                        <textarea
                          type="text"
                          name="mensaje_pdf"
                          id="mensaje_pdf"
                          value={formulario.mensaje_pdf}
                          className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px] min-h-32 max-h-32 appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer ScrollTableVertical"
                          placeholder=" "
                          onChange={handleChange}
                          onInput={(event) => {
                            const { selectionStart, selectionEnd } =
                              event.target;
                            const upperCasedValue =
                              event.target.value.toUpperCase();
                            event.target.value = upperCasedValue;
                            event.target.setSelectionRange(
                              selectionStart,
                              selectionEnd
                            );
                          }}
                          required
                        />
                        <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                          Mensaje
                        </label>
                      </div>
                    </div>

                    <div className="gap-x-4 px-2">
                      <div className="relative z-0 w-full  group">
                        <input
                          type="text"
                          name="servicio_incluido_1_pdf"
                          id="servicio_incluido_1_pdf"
                          value={formulario.servicio_incluido_1_pdf}
                          className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          onChange={handleChange}
                          onInput={(event) => {
                            const { selectionStart, selectionEnd } =
                              event.target;
                            const upperCasedValue =
                              event.target.value.toUpperCase();
                            event.target.value = upperCasedValue;
                            event.target.setSelectionRange(
                              selectionStart,
                              selectionEnd
                            );
                          }}
                          maxLength={100}
                        />
                        <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                          Servicios Incluidos 1
                        </label>
                      </div>
                    </div>
                    <div className="gap-x-4 px-2">
                      <div className="relative z-0 w-full  group">
                        <input
                          type="text"
                          name="servicio_incluido_2_pdf"
                          id="servicio_incluido_2_pdf"
                          value={formulario.servicio_incluido_2_pdf}
                          className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          onChange={handleChange}
                          onInput={(event) => {
                            const { selectionStart, selectionEnd } =
                              event.target;
                            const upperCasedValue =
                              event.target.value.toUpperCase();
                            event.target.value = upperCasedValue;
                            event.target.setSelectionRange(
                              selectionStart,
                              selectionEnd
                            );
                          }}
                          maxLength={100}
                        />
                        <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                          Servicios Incluidos 2
                        </label>
                      </div>
                    </div>
                    <div className="gap-x-4 px-2">
                      <div className="relative z-0 w-full  group">
                        <input
                          type="text"
                          name="servicio_incluido_3_pdf"
                          id="servicio_incluido_3_pdf"
                          value={formulario.servicio_incluido_3_pdf}
                          className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          onChange={handleChange}
                          onInput={(event) => {
                            const { selectionStart, selectionEnd } =
                              event.target;
                            const upperCasedValue =
                              event.target.value.toUpperCase();
                            event.target.value = upperCasedValue;
                            event.target.setSelectionRange(
                              selectionStart,
                              selectionEnd
                            );
                          }}
                          maxLength={100}
                        />
                        <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                          Servicios Incluidos 3
                        </label>
                      </div>
                    </div>
                    <div className="gap-x-4 px-2">
                      <div className="relative z-0 w-full  group">
                        <input
                          type="text"
                          name="servicio_incluido_4_pdf"
                          id="servicio_incluido_4_pdf"
                          value={formulario.servicio_incluido_4_pdf}
                          className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          onChange={handleChange}
                          onInput={(event) => {
                            const { selectionStart, selectionEnd } =
                              event.target;
                            const upperCasedValue =
                              event.target.value.toUpperCase();
                            event.target.value = upperCasedValue;
                            event.target.setSelectionRange(
                              selectionStart,
                              selectionEnd
                            );
                          }}
                          maxLength={100}
                        />
                        <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                          Servicios Incluidos 4
                        </label>
                      </div>
                    </div>
                    <div className="gap-x-4 px-2">
                      <div className="relative z-0 w-full  group">
                        <input
                          type="text"
                          name="servicio_incluido_5_pdf"
                          id="servicio_incluido_5_pdf"
                          value={formulario.servicio_incluido_5_pdf}
                          className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          onChange={handleChange}
                          onInput={(event) => {
                            const { selectionStart, selectionEnd } =
                              event.target;
                            const upperCasedValue =
                              event.target.value.toUpperCase();
                            event.target.value = upperCasedValue;
                            event.target.setSelectionRange(
                              selectionStart,
                              selectionEnd
                            );
                          }}
                          maxLength={100}
                        />
                        <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                          Servicios Incluidos 5
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="Botones mt-8">
                    <div className="flex justify-end gap-x-6 ">
                      <button
                        type="button"
                        onClick={CerraModal}
                        className="px-6 py-2 text-white bg-gradient-to-t   from-gray-400 via-gray-500 to-gray-500 hover:bg-gradient-to-br rounded-md"
                      >
                        Cancelar
                      </button>
                      <button
                        type="submit"
                        className="px-6 py-2 text-white bg-gradient-to-t   from-blue-400 via-blue-500 to-blue-500 hover:bg-gradient-to-br rounded-md"
                      >
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigPDFCotizacion;
