export const EncabTablaCarga = [
  "Ubigeo",
  "Zona",
  "Departamento",
  "Provincia",
  "Distrito",
  "KG Maximo",
  "KG Base",
  "Paquete",
  "T.Min Entrega",
  "T.Max Entrega",
];
