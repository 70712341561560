import { useState, useEffect } from "react";

export const Clientes = ({
  modalClientes,
  setModalClientes,
  idCliente,
  setFormulario,
  setIdAreas,
  idAreas,
  setAreasSeleccionadas,
  setSeleccionarTodos,
  seleccionarTodos,
  areasSeleccionadas,
}) => {
  const [areas, setAreas] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Estado para el texto de búsqueda

  const CerraModal = () => {
    setModalClientes(false);
  };

  useEffect(() => {
    if (idCliente !== "") {
      cargarAreas(idCliente);
    }
  }, [idCliente]);

  const cargarAreas = async (idCliente) => {
    try {
      const response = await fetch(
        `https://sysdemo.byma-ve.com/BackendApiRest/AlertasClientes/ObtenerNombresClientesAreas.php?id_cliente=${idCliente}`
      );
      const data = await response.json();
      setAreas(data);
    } catch (error) {
      console.error("Error fetching empresa:", error);
    }
  };

  const handleCheckboxChange = (id, nombre) => {
    setAreasSeleccionadas((prev) => {
      const newState = {
        ...prev,
        [id]: {
          checked: !prev[id]?.checked,
          nombre: nombre,
        },
      };

      const selectedIds = Object.keys(newState).filter(
        (key) => newState[key].checked
      );
      const selectedIdsString = selectedIds.join(",");
      setIdAreas(selectedIdsString);

      const totalCheckboxes = areas.nombres_areas.length;
      const checkedCount = Object.values(newState).filter(
        (item) => item.checked
      ).length;
      setSeleccionarTodos(checkedCount === totalCheckboxes);
      return newState;
    });
  };

  const handleSeleccionarTodos = () => {
    const nuevoEstado = !seleccionarTodos;
    setSeleccionarTodos(nuevoEstado);

    const nuevosareasSeleccionadas = areas.nombres_areas.reduce(
      (acc, cliente) => ({
        ...acc,
        [cliente.id]: { checked: nuevoEstado, nombre: cliente.nombre_area },
      }),
      {}
    );
    setAreasSeleccionadas(nuevosareasSeleccionadas);

    const selectedIds = Object.keys(nuevosareasSeleccionadas).filter(
      (key) => nuevosareasSeleccionadas[key].checked
    );
    const selectedIdsString = selectedIds.join(",");

    setIdAreas(selectedIdsString);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredAreas = areas.nombres_areas?.filter((cliente) =>
    cliente.nombre_area.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleInputChange = ({ target: { name, value } }) => {
    setFormulario((prevFormulario) => ({
      ...prevFormulario,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const checkedNombres = Object.values(areasSeleccionadas)
      .filter((item) => item.checked)
      .map((item) => item.nombre);

    if (areas?.nombre_cliente?.razon_social_cliente) {
      checkedNombres.push(areas.nombre_cliente.razon_social_cliente);
    }

    const nombresConcatenados = checkedNombres.join(", ");

    handleInputChange({
      target: { name: "nombres_alertas", value: nombresConcatenados },
    });

    handleInputChange({
      target: { name: "id_areas", value: idAreas },
    });

    CerraModal();
  };
  return (
    <>
      <div
        className={`side-panel-container ${
          modalClientes ? "visible" : "invisible"
        } fixed pointer-events-auto left-0 top-0 right-0 h-full bg-[rgba(0,0,0,0.4)] z-[99999999] flex justify-center items-center`}
      >
        <div
          className={`side-panel-cont-container ${
            modalClientes ? "translate-y-[00%]" : "translate-y-[250%]"
          } w-[500px] block absolute transition-transform duration-500 `}
        >
          <div className="side-panel-content-container p-4 ">
            <div className="side-panel-iframe h-full   ">
              <div className="side-panel bg-white  h-full w-auto m-0 rounded-md  ">
                <div className="side-cont-titulo mb-4 text-[25px] px-5 py-2 rounded-t-md  bg-blue-400 w-full font-semibold text-white">
                  <div className="side-titulo flex w-full  ">
                    <h1 className="side-txt mr-9 w-[75%]">Enviar Alertas</h1>
                  </div>
                </div>
                <div className="section-crm pb-4 px-6 ">
                  <div className="mb-2">
                    <label htmlFor="" className="font-semibold">
                      Empresa:
                    </label>
                  </div>

                  <form className="grid grid-cols-[1fr] justify-between gap-5 ScrollTable overflow-y-auto max-h-72 ">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input type="checkbox" checked className="mr-2 ms-2 " />
                        <label className="text-black ">
                          {areas.nombre_cliente &&
                          areas.nombre_cliente.razon_social_cliente
                            ? areas.nombre_cliente.razon_social_cliente
                            : ""}
                        </label>
                      </div>
                    </div>
                  </form>
                  <div className="h-[0.1px] border-b my-4 border-gray-300 shadow "></div>
                  <div className="mb-4 flex items-center justify-between">
                    <div className="cont-txt text-slate-800 text-xl font-light relative w-full flex">
                      <input
                        className="text-sm text-slate-800 h-7 rounded-lg border items-center bg-white focus:bg-gray-50 outline-none px-2 w-full"
                        type="text"
                        placeholder="Buscar Datos"
                        value={searchTerm}
                        onChange={handleSearch}
                      />
                    </div>
                    <div className=" flex items-center whitespace-nowrap">
                      <input
                        type="checkbox"
                        id="seleccionar_todos_checkbox"
                        checked={seleccionarTodos}
                        onChange={handleSeleccionarTodos}
                        className="mr-2 ms-2"
                      />
                      <label
                        htmlFor="seleccionar_todos_checkbox"
                        className="text-black "
                      >
                        Seleccionar Todos
                      </label>
                    </div>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="" className="font-semibold">
                      Areas:
                    </label>
                  </div>

                  <form className="grid grid-cols-[1fr] justify-between gap-[4px] ScrollTable overflow-y-auto max-h-[170px]">
                    {filteredAreas &&
                      filteredAreas.map((cliente, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-between"
                        >
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              id={`${cliente.id}`}
                              className="mr-2 ms-2"
                              checked={
                                areasSeleccionadas[cliente.id]?.checked ||
                                false
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  cliente.id,
                                  cliente.nombre_area
                                )
                              }
                            />
                            <label
                              htmlFor={`${cliente.id}`}
                              className="text-black"
                            >
                              {cliente.nombre_area}
                            </label>
                          </div>
                        </div>
                      ))}
                  </form>
                  <div className="BOTON flex items-center justify-end mt-6">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="text-white mr-4 bg-gradient-to-t from-blue-400 via-blue-500 to-blue-500 hover:bg-gradient-to-br focus:ring-0 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Guardar
                    </button>
                    <button
                      onClick={CerraModal}
                      type="button"
                      className="text-white  bg-gradient-to-t from-gray-400 via-gray-500 to-gray-500 hover:bg-gradient-to-br focus:ring-0 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
