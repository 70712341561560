import React from "react";
import App from "./App";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { createRoot } from "react-dom/client";
import "./index.css";
import { UserProvider } from "./Context/UserContext";
import { NextUIProvider } from "@nextui-org/react";
import { ColorProvider } from "./Context/ColorProvider";
import { NavbarProvider } from "./Context/NavbarContext";
import { EmpresaProvider } from "./Context/EmpresaContext";

const root = document.getElementById("root");
const rootContainer = createRoot(root);
rootContainer.render(
  <UserProvider>
    <NextUIProvider>
      <ColorProvider>
        <EmpresaProvider>
          <NavbarProvider>
            <App />
          </NavbarProvider>
        </EmpresaProvider>
      </ColorProvider>
    </NextUIProvider>
  </UserProvider>
);
