export const EncabTablaValorizado = [
  "Ubigeo",
  "Zona",
  "Departamento",
  "Provincia",
  "Distrito",
  "Producto",
  "S/ Producto",
  "T.Min Entrega",
  "T.Max Entrega",
];
