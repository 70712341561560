import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
const NavBarTarifario = () => {
  const [permisos, setPermisos] = useState("");

  useEffect(() => {
    fetch(
      `https://sysdemo.byma-ve.com/BackendApiRest/Permisos/obtenerPermisos.php?dni_usuario=${localStorage.getItem(
        "user"
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        setPermisos(data);
      })
      .catch((error) => console.error("Error:", error));
  }, []);
  return (
    <>
      <div className="cont-navbar relative  mb-4 bg-white rounded-2xl h-[60px] z-0">
        <div className="navbar w-full h-full flex justify-around text-black p-4 text-center">
          <Link
            to={
              permisos.tarifario_propio_permiso !== 0
                ? "/tarifario-propio"
                : "#"
            }
            className={`elmnts relative text-[15px] font-semibold no-underline transition-all ${
              permisos.tarifario_propio_permiso !== 0
                ? "text-black hover:text-[#1058d0] "
                : "text-gray-300 cursor-not-allowed "
            }`}
          >
            Tarifario Propio
          </Link>
          <Link
            to={
              permisos.tarifario_cliente_permiso !== 0
                ? "/tarifario-cliente"
                : "#"
            }
            className={`elmnts relative text-[15px] font-semibold no-underline transition-all ${
              permisos.tarifario_cliente_permiso !== 0
                ? "text-black hover:text-[#1058d0] "
                : "text-gray-300 cursor-not-allowed "
            }`}
          >
            Tarifario Cliente
          </Link>
          <Link
            to={
              permisos.tarifario_agente_permiso !== 0
                ? "/tarifario-agente"
                : "#"
            }
            className={`elmnts relative text-[15px] font-semibold no-underline transition-all ${
              permisos.tarifario_agente_permiso !== 0
                ? "text-black hover:text-[#1058d0] "
                : "text-gray-300 cursor-not-allowed "
            }`}
          >
            Tarifario Agente
          </Link>
          <Link
            to={
              permisos.tarifario_transportista_permiso !== 0
                ? "/tarifario-transportista"
                : "#"
            }
            className={`elmnts relative text-[15px] font-semibold no-underline transition-all ${
              permisos.tarifario_transportista_permiso !== 0
                ? "text-black hover:text-[#1058d0] "
                : "text-gray-300 cursor-not-allowed "
            }`}
          >
            Tarifario Transportista
          </Link>
        </div>
      </div>
    </>
  );
};

export default NavBarTarifario;
