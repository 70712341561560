import { useState, useEffect } from "react";
import { IconoExcel, Calendario } from "../../../../Iconos/Iconos-NavBar";
import Fechas from "../Modals/Fechas";

import ExcelJS from "exceljs";

function Filtrado({ onSearch }) {
  // Estados para controlar la habilitación de los botones
  const [exportarButtonDisabled, setExportarButtonDisabled] = useState(true);

  // Obtener Cliente Seleccionado
  // Obtener Area Seleccionado
  // Estado para guardar las fechas
  const [fechaDesde, setFechaDesde] = useState("");
  const [fechaHasta, setFechaHasta] = useState("");
  // Variable que controla si se muestra el modal de fechas o no
  const [calendarioClickeado, setCalendarioClickeado] = useState(false);

  // Funcion para detectar la fecha inicial
  const handleFechaDesdeChange = (event) => {
    setFechaDesde(event.target.value);
    setExportarButtonDisabled(false);
  };

  // Función para detectar la fecha final
  const handleFechaHastaChange = (event) => {
    setFechaHasta(event.target.value);
    setExportarButtonDisabled(false);
  };

  // Cambia el estado cuando se hace clic en el calendario
  const handleCalendarioClick = () => {
    handleCloseModal();
    setCalendarioClickeado(true);
  };

  // Cerrar Modal
  const handleCloseModal = () => {
    setCalendarioClickeado(false);
  };

  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    onSearch(value);
  };

  //BACKEND

  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    fetch(
      "https://sysdemo.byma-ve.com/BackendApiRest/Administracion/Cliente/obtener_clientes.php"
    )
      .then((response) => response.json())
      .then((data) => {
        const transformedClientes = [
          { value: "TODOS", label: "ELEGIR TODOS" },
          ...data.map((cliente) => ({
            value: cliente.id,
            label: cliente.razon_social_cliente,
          })),
        ];
        setClientes(transformedClientes);
      });
  }, []);

  const [idCliente, setIdCliente] = useState("");
  const [nombreCliente, setNombreCliente] = useState("");

  const [areas, setAreas] = useState([]);
  const [nombreArea, setNombreArea] = useState([]);

  useEffect(() => {
    if (idCliente) {
      fetch(
        `https://sysdemo.byma-ve.com/BackendApiRest/Administracion/Area/obtenerAreasTarifario.php?id_cliente=${idCliente}`
      )
        .then((response) => response.json())
        .then((data) => {
          const transformedClientes = [
            { value: "TODOS", label: "ELEGIR TODAS LAS AREAS" },
            ...data.map((areas) => ({
              value: areas.id,
              label: areas.nombre_area,
            })),
          ];
          setAreas(transformedClientes);
        });
    }
  }, [idCliente]);

  const [idArea, setIdArea] = useState("");

  function formatearFecha(fecha) {
    const partes = fecha.split("-");
    return `${partes[2]}/${partes[1]}/${partes[0]}`;
  }

  async function descargarExcel() {
    const workbook = new ExcelJS.Workbook();
    try {
      const response = await fetch("/liquidacion_cliente_pie.xlsx");
      const arrayBuffer = await response.arrayBuffer();

      await workbook.xlsx.load(arrayBuffer);
      const worksheet = workbook.getWorksheet(1);

      // Datos Empresa
      const responseEmpresa = await fetch(
        `https://sysdemo.byma-ve.com/BackendApiRest/Configuracion/obtenerEmpresa.php`
      );
      const dataEmpresa = await responseEmpresa.json();

      const logoArrayBuffer = await fetch(dataEmpresa.logo_oscuro_empresa).then(
        (res) => res.arrayBuffer()
      );
      const logoImage = workbook.addImage({
        buffer: logoArrayBuffer,
        extension: "png",
      });

      worksheet.addImage(logoImage, {
        tl: { col: 1, row: 0 },
        br: { col: 3, row: 3 },
      });

      const ahora = new Date();
      let horas = ahora.getHours();
      let minutos = ahora.getMinutes();
      let segundos = ahora.getSeconds();
      const ampm = horas >= 12 ? "P.M." : "A.M.";

      horas = horas % 12;
      horas = horas ? horas : 12;
      minutos = minutos < 10 ? "0" + minutos : minutos;
      segundos = segundos < 10 ? "0" + segundos : segundos;

      const fechaHoraActual = `${ahora.getDate()}/${
        ahora.getMonth() + 1
      }/${ahora.getFullYear()} - ${horas}:${minutos}:${segundos} ${ampm}`;

      worksheet.getCell("D6").value = fechaHoraActual;
      worksheet.getCell("D7").value = 'Clientes de Pie';
      worksheet.getCell("D9").value = `Desde: ${formatearFecha(fechaDesde)}`;
      worksheet.getCell("D10").value = `Hasta: ${formatearFecha(fechaHasta)}`;

      const apiUrl = `https://sysdemo.byma-ve.com/BackendApiRest/Liquidacion/LiquidacionClientesPie/exportarLiquidiacionCliente.php?id_cliente=${idCliente}&id_area=${idArea}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`;
      const apiResponse = await fetch(apiUrl, {
        method: "GET",
      });
      const apiData = await apiResponse.json();

      if (Array.isArray(apiData)) {
        for (let i = 0; i < apiData.length; i++) {
          const rowData = apiData[i];
          const rowNumber = 13 + i;
          const cellStyle = {
            font: { name: "Arial", size: 8, color: { argb: "FF000000" } },
            alignment: { horizontal: "center" },
          };
          worksheet.getCell(`B${rowNumber}`).value = rowData.estado_documento;
          worksheet.getCell(`B${rowNumber}`).style = cellStyle;
          worksheet.getCell(`C${rowNumber}`).value = rowData.tipo_documento;
          worksheet.getCell(`C${rowNumber}`).style = cellStyle;
          worksheet.getCell(`D${rowNumber}`).value = rowData.num_documento;
          worksheet.getCell(`D${rowNumber}`).style = cellStyle;
          worksheet.getCell(`E${rowNumber}`).value = rowData.fecha_orden;
          worksheet.getCell(`E${rowNumber}`).style = cellStyle;
          worksheet.getCell(`F${rowNumber}`).value = rowData.orden_servicio;
          worksheet.getCell(`F${rowNumber}`).style = cellStyle;
          worksheet.getCell(`G${rowNumber}`).value = rowData.destino_entrega;
          worksheet.getCell(`G${rowNumber}`).style = cellStyle;
          worksheet.getCell(`H${rowNumber}`).value = rowData.guia_tracking;
          worksheet.getCell(`H${rowNumber}`).style = cellStyle;
          worksheet.getCell(`I${rowNumber}`).value = rowData.num_intentos;
          worksheet.getCell(`I${rowNumber}`).style = cellStyle;
          worksheet.getCell(`J${rowNumber}`).value =
            rowData.estado_ultimo_intento;
          worksheet.getCell(`J${rowNumber}`).style = cellStyle;
          worksheet.getCell(`K${rowNumber}`).value = rowData.costo_envio;
          worksheet.getCell(`K${rowNumber}`).style = cellStyle;
          worksheet.getCell(`L${rowNumber}`).value = rowData.costo_adicionales;
          worksheet.getCell(`L${rowNumber}`).style = cellStyle;
          worksheet.getCell(`M${rowNumber}`).value = rowData.sub_total;
          worksheet.getCell(`M${rowNumber}`).style = cellStyle;
          worksheet.getCell(`N${rowNumber}`).value = rowData.igv;
          worksheet.getCell(`N${rowNumber}`).style = cellStyle;
          worksheet.getCell(`O${rowNumber}`).value = rowData.precio_total;
          worksheet.getCell(`O${rowNumber}`).style = cellStyle;
        }
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `LiquidacionClientesPie-${fechaDesde}-a-${fechaHasta}.xlsx`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Hubo un error al cargar el archivo Excel", error);
    }
    setFechaHasta("");
    setFechaDesde("");
    setExportarButtonDisabled(true);
  }

  return (
    <>
      <div className="pb-4 cont-filtrar relative ml-3 mt-4 z-1">
        <div className="flex justify-between">
          <div className="cont-btn w-[50%] flex items-center">
            <input
              className="  text-slate-800  font-semibold rounded-[10px]   bg-white  bg-opacity-80  focus:bg-[rgba(255,255,255,1)]  outline-none px-5 py-2  w-[80%] "
              type="text"
              placeholder="Buscar Datos "
              value={searchValue}
              onChange={handleSearchChange}
            />
          </div>
          <div className="cont-fecha flex justify-between gap-x-4 mr-2 ">
            <div className="cont-fecha flex ">
              <button
                onClick={handleCalendarioClick}
                className={`calendario px-[10px] mx-2  p-[5px] text-lg text-white bg-[rgba(255,255,255,0.2)] hover:bg-[rgba(255,255,255,0.5)] border-none rounded-xl 
                }`}
              >
                <Calendario />
              </button>
              <button
                className={`exportar px-[10px] mx-2  p-[5px] text-lg text-white bg-[rgba(255,255,255,0.2)] hover:bg-[rgba(255,255,255,0.5)] 
                border-none rounded-xl mr-4}`}
                onClick={descargarExcel}
              >
                <IconoExcel className="excel text-white" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Fechas
        fechaDesde={fechaDesde}
        fechaHasta={fechaHasta}
        handleFechaDesdeChange={handleFechaDesdeChange}
        handleFechaHastaChange={handleFechaHastaChange}
        calendarioClickeado={calendarioClickeado}
        setCalendarioClickeado={setCalendarioClickeado}
      />
    </>
  );
}

export default Filtrado;
