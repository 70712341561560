import   { useRef, useState, useEffect } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import { IconoCheck } from "../../Iconos/Iconos-NavBar";
import LogoBlanco from "../../Static/Img_Pred/LogoBlanco.webp";
import LogoOscuro from "../../Static/Img_Pred/LogoOscuro.webp";
import { BlockPicker } from "react-color";

const Configuracion = ({
  modalConfiguracion,
  setModalConfiguracion,
  cargarImagenEmpresa,
}) => {
  const [localColor, setLocalColor] = useState("#60a5fa"); // Estado local para manejar cambios antes de guardarlos
  const [datos, setDatos] = useState({});
  const [certificadoSubido, setcertificadoSubido] = useState(false);
  const [nombreCertificado, setnombreCertificado] = useState("");
  const [imageSrc1, setImageSrc1] = useState(LogoBlanco);
  const [imageSrc2, setImageSrc2] = useState(LogoOscuro);
  const [imageSrc3, setImageSrc3] = useState(LogoBlanco);
  const [certificadoPFX, setCertificadoPFX] = useState("");

  useEffect(() => {
    fetch("https://sysdemo.byma-ve.com/BackendApiRest/Configuracion/obtenerEmpresa.php")
      .then((response) => response.json())
      .then((data) => {
        setDatos(data);
      })
      .catch((error) => console.error("Error fetching empresa:", error));
  }, [modalConfiguracion]);

  const [formulario, setFormulario] = useState({
    ruc_empresa: "",
    razon_social_empresa: "",
    direccion_empresa: "",
    numero_empresa: "",
    ubigeo_empresa: "",
    telefono_empresa: "",
    correo_empresa: "",
    usuario_sol_empresa: "",
    clave_sol_empresa: "",
    clave_certificado_empresa: "",
    certificado_empresa: "",
    usuario_api_empresa: "",
    clave_api_empresa: "",
    cuenta_detracciones_empresa: "",
    seguro_empresa: "",
    cuenta_bancaria_soles_1_empresa: "",
    cuenta_bancaria_soles_2_empresa: "",
    cuenta_bancaria_dolares_3_empresa: "",
    factura_empresa: "",
    boleta_empresa: "",
    nota_credito_factura_empresa: "",
    nota_credito_boleta_empresa: "",
    nota_debito_factura_empresa: "",
    nota_debito_boleta_empresa: "",
    comunicacion_de_baja_empresa: "",
    reversion_empresa: "",
    guia_remision_remitente_empresa: "",
    guia_remision_transportista_empresa: "",
    logo_oscuro_empresa: "",
    logo_claro_empresa: "",
    gif_empresa: "",
    color_empresa: localColor,
    id_creador_empresa: localStorage.getItem("id_usuario"),
  });

  useEffect(() => {
    setFormulario({
      ruc_empresa: datos?.ruc_empresa || formulario.ruc_empresa,
      razon_social_empresa:
        datos?.razon_social_empresa || formulario.razon_social_empresa,
      direccion_empresa:
        datos?.direccion_empresa || formulario.direccion_empresa,
      numero_empresa: datos?.numero_empresa || formulario.numero_empresa,
      ubigeo_empresa: datos?.ubigeo_empresa || formulario.ubigeo_empresa,
      telefono_empresa: datos?.telefono_empresa || formulario.telefono_empresa,
      correo_empresa: datos?.correo_empresa || formulario.correo_empresa,
      usuario_sol_empresa:
        datos?.usuario_sol_empresa || formulario.usuario_sol_empresa,
      clave_sol_empresa:
        datos?.clave_sol_empresa || formulario.clave_sol_empresa,
      clave_certificado_empresa:
        datos?.clave_certificado_empresa ||
        formulario.clave_certificado_empresa,
      certificado_empresa: "",
      usuario_api_empresa:
        datos?.usuario_api_empresa || formulario.usuario_api_empresa,
      clave_api_empresa:
        datos?.clave_api_empresa || formulario.clave_api_empresa,
      cuenta_detracciones_empresa:
        datos?.cuenta_detracciones_empresa ||
        formulario.cuenta_detracciones_empresa,
      seguro_empresa: datos?.seguro_empresa || formulario.seguro_empresa,
      cuenta_bancaria_soles_1_empresa:
        datos?.cuenta_bancaria_soles_1_empresa ||
        formulario.cuenta_bancaria_soles_1_empresa,
      cuenta_bancaria_soles_2_empresa:
        datos?.cuenta_bancaria_soles_2_empresa ||
        formulario.cuenta_bancaria_soles_2_empresa,
      cuenta_bancaria_dolares_3_empresa:
        datos?.cuenta_bancaria_dolares_3_empresa ||
        formulario.cuenta_bancaria_dolares_3_empresa,
      factura_empresa: datos?.factura_empresa || formulario.factura_empresa,
      boleta_empresa: datos?.boleta_empresa || formulario.boleta_empresa,
      nota_credito_factura_empresa:
        datos?.nota_credito_factura_empresa ||
        formulario.nota_credito_factura_empresa,
      nota_credito_boleta_empresa:
        datos?.nota_credito_boleta_empresa ||
        formulario.nota_credito_boleta_empresa,
      nota_debito_factura_empresa:
        datos?.nota_debito_factura_empresa ||
        formulario.nota_debito_factura_empresa,
      nota_debito_boleta_empresa:
        datos?.nota_debito_boleta_empresa ||
        formulario.nota_debito_boleta_empresa,
      comunicacion_de_baja_empresa:
        datos?.comunicacion_de_baja_empresa ||
        formulario.comunicacion_de_baja_empresa,
      reversion_empresa:
        datos?.reversion_empresa || formulario.reversion_empresa,
      guia_remision_remitente_empresa:
        datos?.guia_remision_remitente_empresa ||
        formulario.guia_remision_remitente_empresa,
      guia_remision_transportista_empresa:
        datos?.guia_remision_transportista_empresa ||
        formulario.guia_remision_transportista_empresa,
      logo_oscuro_empresa: "",
      logo_claro_empresa: "",
      gif_empresa: "",
      color_empresa: datos?.color_empresa || formulario.color_empresa,
      id_creador_empresa:
        datos?.id_creador_empresa || formulario.id_creador_empresa,
    });

    setDepartamentoSeleccionado(datos?.departamento_id || "");
    setProvinciaSeleccionada(datos?.provincia_id || "");
    setDistritoSeleccionada(datos?.ubigeo_empresa || formulario.ubigeo_empresa);
    setLocalColor(datos?.color_empresa || formulario.color_empresa);
    setcertificadoSubido(
      !!datos?.certificado_empresa || !!formulario.certificado_empresa
    );
    setImageSrc1(datos?.logo_claro_empresa || LogoBlanco);
    setImageSrc2(datos?.logo_oscuro_empresa || LogoOscuro);
    setImageSrc3(datos?.gif_empresa || LogoBlanco);
  }, [datos]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormulario((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleColorChange = (color) => {
    setLocalColor(color.hex);
    setFormulario((prevValues) => ({
      ...prevValues,
      color_empresa: color.hex,
    }));
  };

  const CerraModal = () => {
    setModalConfiguracion(false);
    resetFormState();
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);

  const handleButtonClick1 = (event) => {
    event.preventDefault();
    fileInputRef1.current.click();
  };

  const handleButtonClick2 = (event) => {
    event.preventDefault();
    fileInputRef2.current.click();
  };

  const handleButtonClick3 = (event) => {
    event.preventDefault();
    fileInputRef3.current.click();
  };

  const handleButtonClick4 = (event) => {
    event.preventDefault();
    fileInputRef4.current.click();
  };

  const handleFileChange1 = (event) => {
    const { name, files: selectedFiles } = event.target;
    const file = selectedFiles[0];

    if (file) {
      const validTypes = ["image/webp"];
      if (!validTypes.includes(file.type)) {
        alert("El archivo debe ser en formato .webp");
        return;
      }
      const maxSize = 100 * 1024;
      if (file.size > maxSize) {
        alert("El archivo debe tener un tamaño máximo de 100KB");
        return;
      }
      const img = new Image();
      img.onload = () => {
        if (img.width !== 300 || img.height !== 140) {
          alert("Las dimensiones de la imagen deben ser 300x140 píxeles");
        } else {
          setFormulario((prevFiles) => ({
            ...prevFiles,
            [name]: file,
          }));

          const fileURL = URL.createObjectURL(file);
          setImageSrc1(fileURL);
        }
      };
      img.src = URL.createObjectURL(file);
    }
  };

  const handleFileChange2 = (event) => {
    const { name, files: selectedFiles } = event.target;
    const file = selectedFiles[0];

    if (file) {
      const validTypes = ["image/png"];
      if (!validTypes.includes(file.type)) {
        alert("El archivo debe ser en formato .png");
        return;
      }
      const maxSize = 100 * 1024;
      if (file.size > maxSize) {
        alert("El archivo debe tener un tamaño máximo de 100KB");
        return;
      }
      const img = new Image();
      img.onload = () => {
        if (img.width !== 300 || img.height !== 140) {
          alert("Las dimensiones de la imagen deben ser 300x140 píxeles");
        } else {
          setFormulario((prevFiles) => ({
            ...prevFiles,
            [name]: file,
          }));

          const fileURL = URL.createObjectURL(file);
          setImageSrc2(fileURL);
        }
      };
      img.src = URL.createObjectURL(file);
    }
  };

  const handleFileChange3 = (event) => {
    const { name, files: selectedFiles } = event.target;
    const file = selectedFiles[0];

    if (file) {
      const maxSize = 800 * 1024;
      if (file.size > maxSize) {
        alert("El archivo debe tener un tamaño máximo de 800KB");
        return;
      }
      const img = new Image();
      img.onload = () => {
        if (img.width !== 500 || img.height !== 200) {
          alert("Las dimensiones del gif deben ser 500x200 píxeles");
        } else {
          setFormulario((prevFiles) => ({
            ...prevFiles,
            [name]: file,
          }));

          const fileURL = URL.createObjectURL(file);
          setImageSrc3(fileURL);
        }
      };
      img.src = URL.createObjectURL(file);
    }
  };

  const handleFileChangeCertificado = (event) => {
    const { name, files: selectedFiles } = event.target;
    const file = selectedFiles[0];

    if (file) {
      setFormulario((prevFiles) => ({
        ...prevFiles,
        [name]: file,
      }));
      setnombreCertificado(file.name);
      setCertificadoPFX(file);
    } else {
      setnombreCertificado("");
      setCertificadoPFX("");
    }
  };

  // Departamento - Provincia - Distrito / Select
  const [departamentos, setDepartamentos] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [distritos, setDistritos] = useState([]);
  const [departamentoSeleccionado, setDepartamentoSeleccionado] = useState("");
  const [provinciaSeleccionada, setProvinciaSeleccionada] = useState("");
  const [distritoSeleccionada, setDistritoSeleccionada] = useState("");

  useEffect(() => {
    // Obtener departamentos
    fetch(
      "https://sysdemo.byma-ve.com/BackendApiRest/Ubigeo/select_ubigeo.php?action=departamentos"
    )
      .then((response) => response.json())
      .then((data) => {
        const transformedDepartamentos = data.map((departamento) => ({
          value: departamento.id,
          label: departamento.nombre_dep,
        }));
        setDepartamentos(transformedDepartamentos);
      })
      .catch((error) => console.error("Error fetching departamentos:", error));
  }, []);

  useEffect(() => {
    if (departamentoSeleccionado) {
      // Obtener provincias por departamento
      fetch(
        `https://sysdemo.byma-ve.com/BackendApiRest/Ubigeo/select_ubigeo.php?action=provincias&id=${departamentoSeleccionado}`
      )
        .then((response) => response.json())
        .then((data) => {
          const transformedDepartamentos = data.map((provincia) => ({
            value: provincia.id,
            label: provincia.nombre_prov,
          }));
          setProvincias(transformedDepartamentos);
        })
        .catch((error) => console.error("Error fetching provincias:", error));
    }
  }, [departamentoSeleccionado]);

  useEffect(() => {
    if (provinciaSeleccionada) {
      // Obtener distritos por provincia
      fetch(
        `https://sysdemo.byma-ve.com/BackendApiRest/Ubigeo/select_ubigeo.php?action=distritos&id=${provinciaSeleccionada}`
      )
        .then((response) => response.json())
        .then((data) => {
          const transformedDepartamentos = data.map((provincia) => ({
            value: provincia.ubigeo,
            label: provincia.nombre_dist,
          }));
          setDistritos(transformedDepartamentos);
        })
        .catch((error) => console.error("Error fetching distritos:", error));
    }
  }, [provinciaSeleccionada]);

  const handleDepartamentoChange = (event) => {
    setDepartamentoSeleccionado(event.target.value);
    setProvinciaSeleccionada("");
    setDistritoSeleccionada("");
    setDistritos([]);
  };

  const handleProvinciaChange = (event) => {
    setProvinciaSeleccionada(event.target.value);
    setDistritoSeleccionada("");
  };

  const handleDistritoChange = (event) => {
    const distritoSeleccionado = event.target.value;
    setDistritoSeleccionada(distritoSeleccionado);
  };

  const [resetForm, setResetForm] = useState(false);

  const resetFormState = () => {
    setResetForm((prevResetForm) => !prevResetForm);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (const key in formulario) {
      if (key !== "certificado_empresa") {
        formData.append(key, formulario[key]);
      }
    }

    let urlPrefirmadaVal = "";

    if (certificadoPFX) {
      const urlResponse = await obtenerUrlPrefirmada(
        "certificado_digital",
        certificadoPFX.type
      );
      const { url: preSignedUrl, permanentUrl } = urlResponse;
      urlPrefirmadaVal = preSignedUrl;
      formData.append("certificado_empresa", permanentUrl);
    } else {
      formData.append("certificado_empresa", "");
    }

    try {
      const response = await fetch(
        "https://sysdemo.byma-ve.com/BackendApiRest/Configuracion/guardarEmpresa.php",
        {
          method: "POST",
          body: formData,
        }
      );

      const responseData = await response.json();
      if (responseData.success) {
        if (certificadoPFX) {
          try {
            const amazonResponse = await fetch(urlPrefirmadaVal, {
              method: "PUT",
              headers: {
                "Content-Type": certificadoPFX.type,
              },
              body: certificadoPFX,
            });

            if (amazonResponse.ok) {
              Swal.fire({
                icon: "success",
                title: responseData.message,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: responseData.message,
              });
            }
          } catch (amazonError) {
            console.error("Error en la subida a Amazon:", amazonError);
            Swal.fire({
              icon: "warning",
              title: "Guardado parcial",
              text:
                "Los datos se guardaron pero hubo un error al subir la imagen: " +
                amazonError.message,
            });
          }
        } else {
          Swal.fire({
            icon: "success",
            title: responseData.message,
          });
          CerraModal();
          cargarImagenEmpresa();
        }
      } else {
        Swal.fire({
          icon: "error",
          title: responseData.message,
        });
      }
    } catch (error) {
      console.error("Error al realizar la solicitud:", error);
      Swal.fire({
        icon: "error",
        title: "Error de red",
        text: "Hubo un problema de red al intentar actualizar los datos.",
      });
    }
  };

  const obtenerUrlPrefirmada = async (filename, fileType) => {
    try {
      const rutaCarpetas = `CertificadoDigital`;

      const response = await fetch(
        `https://sysdemo.byma-ve.com/BackendApiRest/services/getPresignedUrl.php?filename=${encodeURIComponent(
          filename
        )}&filetype=${encodeURIComponent(
          fileType
        )}&nombreCarpeta=${encodeURIComponent(rutaCarpetas)}`
      );
      const data = await response.json();
      if (data.url) {
        return data;
      } else {
        throw new Error(data.error || "Error obteniendo la URL pre-firmada");
      }
    } catch (error) {
      console.error("Error en la petición:", error);
      throw error;
    }
  };

  const customStyles2 = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "none",
      fontSize: "14px",
      borderRadius: "0px",
      height: "16px",
      borderBottom: "1px solid #9ca3af",
      boxShadow: "none",
      "&:active": {
        borderColor: "#0389fb ",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "10px 0",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "45px",
    }),

    menu: (provided) => ({
      ...provided,
      marginTop: "5px",
      borderRadius: "4px",
      boxShadow:
        "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "12px",
      borderRadius: "5px",
    }),
  };
  return (
    <>
      <div
        className={`side-panel-container ${
          modalConfiguracion ? "visible" : "invisible"
        } fixed pointer-events-auto left-0 top-0 right-0 h-full bg-[rgba(0,0,0,0.4)] z-[11]`}
      >
        <div
          className={`side-panel-cont-container ${
            modalConfiguracion ? "translate-x-[0%]" : "translate-x-[100%]"
          } w-[1000px] h-full block absolute top-0 right-0 bottom-0 bg-slate-100 transition-transform duration-1000 z-[12]`}
        >
          <div className="side-panel-content-container block absolute top-0 right-0 bottom-0 left-0 p-0 ">
            <div className="side-panel-iframe relative w-full h-full">
              <div className="side-panel  p-[0_15px_21px_21px] h-full w-auto m-0 overflow-y-auto  ScrollTableVertical ">
                <div className="side-cont-titulo p-[12px_0] text-[25px] font-medium text-slate-900 opacity-80"></div>
                <form
                  key={resetForm}
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                >
                  <div className="grid grid-cols-[1fr,3fr] gap-x-4">
                    <div className="space-y-6">
                      <div className=" w-full bg-slate-600 rounded-lg ">
                        <div className=" px-6 text-white bg-gradient-to-t   from-blue-400 via-blue-500 to-blue-500 hover:bg-gradient-to-br rounded-md text-center">
                          <button
                            onClick={handleButtonClick1}
                            className="px-6 py-1  text-white"
                          >
                            Cambiar Logo
                          </button>
                          <input
                            name="logo_claro_empresa"
                            id="logo_claro_empresa"
                            type="file"
                            ref={fileInputRef1}
                            style={{ display: "none" }}
                            onChange={handleFileChange1}
                            accept=".webp"
                            required={!datos.logo_claro_empresa}
                          />
                        </div>
                        <div className="">
                          <div className="flex justify-center items-center ">
                            <img
                              src={imageSrc1}
                              alt=""
                              className="object-contain"
                            />
                          </div>
                        </div>
                      </div>
                      <div className=" text-xs px-2 text-gray-500 text-semibold">
                        Recomendaciones: Webp, Logo blanco, tamaño máximo de
                        100KB y 300 x 140 pixeles.
                      </div>
                      <div className=" w-full bg-white rounded-lg ">
                        <div className=" px-6 py-0 text-white bg-gradient-to-t   from-blue-400 via-blue-500 to-blue-500 hover:bg-gradient-to-br rounded-md text-center">
                          <button
                            onClick={handleButtonClick2}
                            className="px-6 py-1  text-white"
                          >
                            Cambiar Logo
                          </button>
                          <input
                            type="file"
                            name="logo_oscuro_empresa"
                            id="logo_oscuro_empresa"
                            ref={fileInputRef2}
                            style={{ display: "none" }}
                            onChange={handleFileChange2}
                            accept=".png"
                            required={!datos.logo_oscuro_empresa}
                          />
                        </div>
                        <div className="">
                          <div className="flex justify-center items-center ">
                            <img
                              src={imageSrc2}
                              alt=""
                              className="object-contain"
                            />
                          </div>
                        </div>
                      </div>
                      <div className=" text-xs px-2 text-gray-500 text-semibold">
                        Recomendaciones: Png, Logo oscuro, tamaño máximo de
                        100KB y 300 x 140 pixeles.
                      </div>
                      <div className=" w-full bg-slate-600 rounded-lg ">
                        <div className=" px-6 text-white bg-gradient-to-t   from-blue-400 via-blue-500 to-blue-500 hover:bg-gradient-to-br rounded-md text-center">
                          <button
                            onClick={handleButtonClick3}
                            className="px-6 py-1  text-white"
                          >
                            Cambiar Gif
                          </button>
                          <input
                            type="file"
                            name="gif_empresa"
                            id="gif_empresa"
                            ref={fileInputRef3}
                            style={{ display: "none" }}
                            onChange={handleFileChange3}
                            accept=".gif"
                            required={!datos.gif_empresa}
                          />
                        </div>
                        <div className="">
                          <div className="flex justify-center items-center ">
                            <img
                              src={imageSrc3}
                              alt=""
                              className="object-contain"
                            />
                          </div>
                        </div>
                      </div>
                      <div className=" text-xs px-2 text-gray-500 text-semibold">
                        Recomendaciones: Gif, Logo blanco y fondo transparente,
                        tamaño máximo de 800KB y 500 x 200 pixeles.
                      </div>
                      <div className="w-full">
                        <BlockPicker
                          color={localColor}
                          onChange={handleColorChange}
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="space-y-6 mr-4 border-2 rounded-t-xl bg-white shadow  ScrollTable">
                      <div className="bg-blue-400 rounded-lg  px-4 py-1 text-white">
                        <h1>Mi perfil</h1>
                      </div>
                      <div className="p-4 space-y-6 ">
                        <div className="grid grid-cols-3 gap-x-4">
                          <div className="relative z-0 w-full  group">
                            <input
                              type="text"
                              name="razon_social_empresa"
                              id="razon_social_empresa"
                              className="uppercase block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              required
                              value={formulario.razon_social_empresa}
                              onChange={handleChange}
                              onInput={(event) => {
                                const { selectionStart, selectionEnd } =
                                  event.target;
                                const upperCasedValue =
                                  event.target.value.toUpperCase();
                                event.target.value = upperCasedValue;
                                event.target.setSelectionRange(
                                  selectionStart,
                                  selectionEnd
                                );
                              }}
                              maxLength={255}
                            />
                            <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                              Razon Social
                            </label>
                          </div>
                          <div className="relative z-0 w-full  group">
                            <input
                              type="text"
                              name="ruc_empresa"
                              id="ruc_empresa"
                              className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              required
                              maxLength={20}
                              value={formulario.ruc_empresa}
                              onChange={handleChange}
                              onInput={(event) => {
                                const { selectionStart, selectionEnd } =
                                  event.target;
                                const upperCasedValue =
                                  event.target.value.toUpperCase();
                                event.target.value = upperCasedValue;
                                event.target.setSelectionRange(
                                  selectionStart,
                                  selectionEnd
                                );
                              }}
                            />
                            <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                              RUC
                            </label>
                          </div>
                          <div className="relative z-0 w-full  group"></div>
                        </div>
                        <div className="grid grid-cols-[1.5fr,1fr] gap-x-4">
                          <div className="relative z-0 w-full  group">
                            <input
                              type="text"
                              name="direccion_empresa"
                              id="direccion_empresa"
                              className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              required
                              value={formulario.direccion_empresa}
                              onChange={handleChange}
                              onInput={(event) => {
                                const { selectionStart, selectionEnd } =
                                  event.target;
                                const upperCasedValue =
                                  event.target.value.toUpperCase();
                                event.target.value = upperCasedValue;
                                event.target.setSelectionRange(
                                  selectionStart,
                                  selectionEnd
                                );
                              }}
                              maxLength={255}
                            />
                            <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                              Direccion
                            </label>
                          </div>
                          <div className="relative z-0 w-full  group">
                            <input
                              type="text"
                              name="numero_empresa"
                              id="numero_empresa"
                              className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              required
                              value={formulario.numero_empresa}
                              onChange={handleChange}
                              onInput={(event) => {
                                const { selectionStart, selectionEnd } =
                                  event.target;
                                const upperCasedValue =
                                  event.target.value.toUpperCase();
                                event.target.value = upperCasedValue;
                                event.target.setSelectionRange(
                                  selectionStart,
                                  selectionEnd
                                );
                              }}
                              maxLength={15}
                            />
                            <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                              Numero
                            </label>
                          </div>
                        </div>
                        <div className="grid grid-cols-3 gap-x-4">
                          <div className="relative  w-full  group">
                            <Select
                              name="departamento"
                              id="departamento"
                              placeholder="Elegir Departamento"
                              styles={customStyles2}
                              options={departamentos}
                              required
                              onChange={(selectedOption) =>
                                handleDepartamentoChange({
                                  target: {
                                    name: "departamento",
                                    value: selectedOption.value,
                                  },
                                })
                              }
                              value={
                                departamentoSeleccionado
                                  ? departamentos.find(
                                      (option) =>
                                        option.value ===
                                        departamentoSeleccionado
                                    )
                                  : null
                              }
                            ></Select>
                            <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3   origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                              Departamento
                            </label>
                          </div>
                          <div className="relative w-full  group">
                            <Select
                              name="provincia"
                              id="provincia"
                              placeholder="Elegir Provincia"
                              styles={customStyles2}
                              options={provincias}
                              value={
                                provinciaSeleccionada
                                  ? provincias.find(
                                      (option) =>
                                        option.value === provinciaSeleccionada
                                    )
                                  : null
                              }
                              onChange={(selectedOption) =>
                                handleProvinciaChange({
                                  target: {
                                    name: "provincia",
                                    value: selectedOption.value,
                                  },
                                })
                              }
                              isDisabled={!departamentoSeleccionado}
                              required
                            ></Select>
                            <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                              Provincia
                            </label>
                          </div>
                          <div className="relative  w-full  group">
                            <Select
                              name="ubigeo_empresa"
                              id="ubigeo_empresa"
                              placeholder="Elegir Distrito"
                              styles={customStyles2}
                              options={distritos}
                              value={
                                distritoSeleccionada
                                  ? distritos.find(
                                      (option) =>
                                        option.value === distritoSeleccionada
                                    )
                                  : null
                              }
                              onChange={(selectedOption) => {
                                const event = {
                                  target: {
                                    name: "ubigeo_empresa",
                                    value: selectedOption.value,
                                  },
                                };
                                handleChange(event);
                                handleDistritoChange(event);
                              }}
                              isDisabled={!provinciaSeleccionada}
                              required
                            ></Select>
                            <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                              Distrito
                            </label>
                          </div>
                        </div>
                        <div className="grid grid-cols-3 gap-x-4">
                          <div className="relative z-0 w-full  group">
                            <input
                              type="number"
                              name="telefono_empresa"
                              id="telefono_empresa"
                              className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              required
                              value={formulario.telefono_empresa}
                              onChange={handleChange}
                              onInput={(event) => {
                                const { selectionStart, selectionEnd } =
                                  event.target;
                                const upperCasedValue =
                                  event.target.value.toUpperCase();
                                event.target.value = upperCasedValue;
                                event.target.setSelectionRange(
                                  selectionStart,
                                  selectionEnd
                                );
                              }}
                              maxLength={15}
                            />
                            <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                              Telefono
                            </label>
                          </div>
                          <div className="relative z-0 w-full  group">
                            <input
                              type="email"
                              name="correo_empresa"
                              id="correo_empresa"
                              value={formulario.correo_empresa}
                              className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              required
                              onChange={handleChange}
                              maxLength={100}
                            />
                            <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                              Correo
                            </label>
                          </div>
                        </div>
                        <div className="INFO SUNAT space-y-6">
                          <div className="bg-blue-400 w-ful rounded-md px-2 text-white py-1">
                            <h1>Informacion SUNAT</h1>
                          </div>
                          <div className="Cuerpo grid grid-cols-3 px-2 gap-x-4">
                            <div className="relative z-0 w-full    group">
                              <input
                                type="text"
                                name="usuario_sol_empresa"
                                id="usuario_sol_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                
                                value={formulario.usuario_sol_empresa}
                                onChange={handleChange}
                                maxLength={50}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Usuario SOL
                              </label>
                            </div>
                            <div className="relative z-0 w-full   group">
                              <input
                                type="text"
                                name="clave_sol_empresa"
                                id="clave_sol_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                
                                value={formulario.clave_sol_empresa}
                                onChange={handleChange}
                                maxLength={50}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Clave SOL
                              </label>
                            </div>
                            <div className="relative z-0 w-full   group">
                              <input
                                type="text"
                                name="clave_certificado_empresa"
                                id="clave_certificado_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                
                                value={formulario.clave_certificado_empresa}
                                onChange={handleChange}
                                maxLength={50}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Clave Certificado
                              </label>
                            </div>
                          </div>
                          <div className="Cuerpo flex justify-between gap-x-2 px-2 w-full ">
                            <div className="flex gap-x-2">
                              <div className="">
                                <div className=" items-center space-x-2 ">
                                  <label
                                    onClick={handleButtonClick4}
                                    htmlFor="file-upload"
                                    className="cursor-pointer text-blue-500 hover:text-blue-700 flex items-center"
                                  >
                                    <span>Certificado</span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      className="w-5 h-5 ml-1"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M15.172 7l-6.414 6.414a2 2 0 102.828 2.828l6.414-6.414a4 4 0 10-5.656-5.656L7 7.757M7 13v3.586a2 2 0 01-.586 1.414L4 20h16"
                                      />
                                    </svg>
                                  </label>
                                  <input
                                    id="certificado_empresa"
                                    name="certificado_empresa"
                                    type="file"
                                    style={{ display: "none" }}
                                    accept=".pfx"
                                    onChange={handleFileChangeCertificado}
                                    ref={fileInputRef4}
                                  />
                                </div>
                              </div>
                              <label htmlFor="" className="text-gray-500">
                                {nombreCertificado || ""}
                              </label>
                            </div>{" "}
                            {certificadoSubido && (
                              <div className="flex bg-green-400 px-2 align-middle self-center rounded-md items-center text-white">
                                <p className="text-2xl font-bold">
                                  <IconoCheck />
                                </p>
                                <p className="text-sm">CERTIFICADO CARGADO</p>
                              </div>
                            )}
                          </div>

                          <div className="text-xs text-gray-400 px-2 ">
                            <span>
                              *Dato sensible al activar Envío a Producción,
                              verificar si está seguro.
                            </span>
                          </div>
                        </div>
                        <div className="APISUNAT space-y-6">
                          <div className="bg-blue-400 w-ful rounded-md px-2 text-white py-1">
                            <h1>API SUNAT</h1>
                          </div>
                          <div className="Cuerpo grid grid-cols-3 px-2 gap-x-4">
                            <div className="relative z-0 w-full    group">
                              <input
                                type="text"
                                name="usuario_api_empresa"
                                id="usuario_api_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                
                                value={formulario.usuario_api_empresa}
                                onChange={handleChange}
                                maxLength={50}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Usuario API
                              </label>
                            </div>
                            <div className="relative z-0 w-full   group">
                              <input
                                type="text"
                                name="clave_api_empresa"
                                id="clave_api_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                
                                value={formulario.clave_api_empresa}
                                onChange={handleChange}
                                maxLength={50}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Clave API
                              </label>
                            </div>
                          </div>

                          <div className="text-xs text-gray-400 px-2 ">
                            <span>
                              *Los envíos de Guía de Remisión son directamente a
                              producción SUNAT
                            </span>
                          </div>
                        </div>
                        <div className="Detracciones space-y-6">
                          <div className="bg-blue-400 w-ful rounded-md px-2 text-white py-1">
                            <h1 className="bg-blue-400">
                              Cuenta de Detracciones / Seguro de Carga
                            </h1>
                          </div>
                          <div className="Cuerpo grid grid-cols-3 px-2 gap-x-4">
                            <div className="relative w-full group ">
                              <input
                                type="text"
                                name="cuenta_detracciones_empresa"
                                id="cuenta_detracciones_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                
                                value={formulario.cuenta_detracciones_empresa}
                                onChange={handleChange}
                                maxLength={20}
                              />
                              <label className="peer-focus:font-medium  absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                N° de Cta. Banco de la Nacion
                              </label>
                            </div>
                            <div className="relative w-full group ">
                              <input
                                type="number"
                                step={0.1}
                                id="seguro_empresa"
                                name="seguro_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                value={formulario.seguro_empresa}
                                required
                                onChange={handleChange}
                              />
                              <label className="peer-focus:font-medium  absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Seguro %
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="DebitoAutomatico space-y-6">
                          <div className="bg-blue-400 w-ful rounded-md px-2 text-white py-1">
                            <h1>Cuentas Bancarias</h1>
                          </div>
                          <div className="Cuerpo grid grid-cols-3 px-2 gap-x-4">
                            <div className="relative z-0 w-full    group">
                              <input
                                type="text"
                                name="cuenta_bancaria_soles_1_empresa"
                                id="cuenta_bancaria_soles_1_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                
                                value={
                                  formulario.cuenta_bancaria_soles_1_empresa
                                }
                                onChange={handleChange}
                                maxLength={20}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Cuenta Bancaria Soles 1
                              </label>
                            </div>
                            <div className="relative z-0 w-full   group">
                              <input
                                type="text"
                                name="cuenta_bancaria_soles_2_empresa"
                                id="cuenta_bancaria_soles_2_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                value={
                                  formulario.cuenta_bancaria_soles_2_empresa
                                }
                                onChange={handleChange}
                                maxLength={20}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Cuenta Bancaria Soles 2
                              </label>
                            </div>
                            <div className="relative z-0 w-full   group">
                              <input
                                type="text"
                                name="cuenta_bancaria_dolares_3_empresa"
                                id="cuenta_bancaria_dolares_3_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                value={
                                  formulario.cuenta_bancaria_dolares_3_empresa
                                }
                                onChange={handleChange}
                                maxLength={20}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Cuenta Bancaria Dolares 3
                              </label>
                            </div>
                          </div>

                          <div className="text-xs text-gray-400 px-2 ">
                            <span>
                              *Es indispensable que la cuenta bancaria sea
                              válida y se encuentre registrada a nombre de la
                              empresa o persona jurídica
                            </span>
                          </div>
                        </div>
                        <div className="REGISTRASERIES space-y-6">
                          <div className="bg-blue-400 w-ful rounded-md px-2 text-white py-1">
                            <h1>Registra tus Series</h1>
                          </div>
                          <div className="grid grid-cols-3 gap-x-4 px-2">
                            <div className="relative z-0 w-full  group">
                              <input
                                type="text"
                                name="factura_empresa"
                                id="factura_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                
                                value={formulario.factura_empresa}
                                onChange={handleChange}
                                onInput={(event) => {
                                  const { selectionStart, selectionEnd } =
                                    event.target;
                                  const upperCasedValue =
                                    event.target.value.toUpperCase();
                                  event.target.value = upperCasedValue;
                                  event.target.setSelectionRange(
                                    selectionStart,
                                    selectionEnd
                                  );
                                }}
                                maxLength={20}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Factura
                              </label>
                            </div>
                            <div className="relative z-0 w-full  group">
                              <input
                                type="text"
                                name="boleta_empresa"
                                id="boleta_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                
                                value={formulario.boleta_empresa}
                                onChange={handleChange}
                                onInput={(event) => {
                                  const { selectionStart, selectionEnd } =
                                    event.target;
                                  const upperCasedValue =
                                    event.target.value.toUpperCase();
                                  event.target.value = upperCasedValue;
                                  event.target.setSelectionRange(
                                    selectionStart,
                                    selectionEnd
                                  );
                                }}
                                maxLength={20}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Boleta
                              </label>
                            </div>
                            <div className="relative z-0 w-full  group">
                              <input
                                type="text"
                                name="nota_credito_factura_empresa"
                                id="nota_credito_factura_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                
                                value={formulario.nota_credito_factura_empresa}
                                onChange={handleChange}
                                onInput={(event) => {
                                  const { selectionStart, selectionEnd } =
                                    event.target;
                                  const upperCasedValue =
                                    event.target.value.toUpperCase();
                                  event.target.value = upperCasedValue;
                                  event.target.setSelectionRange(
                                    selectionStart,
                                    selectionEnd
                                  );
                                }}
                                maxLength={20}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Nota de Credito Factura
                              </label>
                            </div>
                          </div>
                          <div className="grid grid-cols-3 gap-x-4 px-2">
                            <div className="relative z-0 w-full  group">
                              <input
                                type="text"
                                name="nota_credito_boleta_empresa"
                                id="nota_credito_boleta_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                
                                value={formulario.nota_credito_boleta_empresa}
                                onChange={handleChange}
                                onInput={(event) => {
                                  const { selectionStart, selectionEnd } =
                                    event.target;
                                  const upperCasedValue =
                                    event.target.value.toUpperCase();
                                  event.target.value = upperCasedValue;
                                  event.target.setSelectionRange(
                                    selectionStart,
                                    selectionEnd
                                  );
                                }}
                                maxLength={20}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Nota de Credito Boleta
                              </label>
                            </div>
                            <div className="relative z-0 w-full  group">
                              <input
                                type="text"
                                name="nota_debito_factura_empresa"
                                id="nota_debito_factura_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                
                                value={formulario.nota_debito_factura_empresa}
                                onChange={handleChange}
                                onInput={(event) => {
                                  const { selectionStart, selectionEnd } =
                                    event.target;
                                  const upperCasedValue =
                                    event.target.value.toUpperCase();
                                  event.target.value = upperCasedValue;
                                  event.target.setSelectionRange(
                                    selectionStart,
                                    selectionEnd
                                  );
                                }}
                                maxLength={20}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Nota de Debito Factura
                              </label>
                            </div>
                            <div className="relative z-0 w-full  group">
                              <input
                                type="text"
                                name="nota_debito_boleta_empresa"
                                id="nota_debito_boleta_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                
                                value={formulario.nota_debito_boleta_empresa}
                                onChange={handleChange}
                                onInput={(event) => {
                                  const { selectionStart, selectionEnd } =
                                    event.target;
                                  const upperCasedValue =
                                    event.target.value.toUpperCase();
                                  event.target.value = upperCasedValue;
                                  event.target.setSelectionRange(
                                    selectionStart,
                                    selectionEnd
                                  );
                                }}
                                maxLength={20}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Nota de Debito Boleta
                              </label>
                            </div>
                          </div>
                          <div className="grid grid-cols-3 gap-x-4 px-2">
                            <div className="relative z-0 w-full  group"></div>
                          </div>
                          <div className="grid grid-cols-3 gap-x-4 px-2">
                            <div className="relative z-0 w-full  group">
                              <input
                                type="text"
                                name="comunicacion_de_baja_empresa"
                                id="comunicacion_de_baja_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                
                                value={formulario.comunicacion_de_baja_empresa}
                                onChange={handleChange}
                                onInput={(event) => {
                                  const { selectionStart, selectionEnd } =
                                    event.target;
                                  const upperCasedValue =
                                    event.target.value.toUpperCase();
                                  event.target.value = upperCasedValue;
                                  event.target.setSelectionRange(
                                    selectionStart,
                                    selectionEnd
                                  );
                                }}
                                maxLength={20}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Comunicacion de Baja
                              </label>
                            </div>
                            <div className="relative z-0 w-full  group">
                              <input
                                type="text"
                                name="reversion_empresa"
                                id="reversion_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                
                                value={formulario.reversion_empresa}
                                onChange={handleChange}
                                onInput={(event) => {
                                  const { selectionStart, selectionEnd } =
                                    event.target;
                                  const upperCasedValue =
                                    event.target.value.toUpperCase();
                                  event.target.value = upperCasedValue;
                                  event.target.setSelectionRange(
                                    selectionStart,
                                    selectionEnd
                                  );
                                }}
                                maxLength={20}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Reversion
                              </label>
                            </div>
                            <div className="relative z-0 w-full  group">
                              <input
                                type="text"
                                name="guia_remision_remitente_empresa"
                                id="guia_remision_remitente_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                
                                value={
                                  formulario.guia_remision_remitente_empresa
                                }
                                onChange={handleChange}
                                onInput={(event) => {
                                  const { selectionStart, selectionEnd } =
                                    event.target;
                                  const upperCasedValue =
                                    event.target.value.toUpperCase();
                                  event.target.value = upperCasedValue;
                                  event.target.setSelectionRange(
                                    selectionStart,
                                    selectionEnd
                                  );
                                }}
                                maxLength={20}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Guia de Remision Remitente
                              </label>
                            </div>
                          </div>
                          <div className="grid grid-cols-3 gap-x-4 px-2">
                            <div className="relative z-0 w-full  group">
                              <input
                                type="text"
                                name="guia_remision_transportista_empresa"
                                id="guia_remision_transportista_empresa"
                                className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                
                                value={
                                  formulario.guia_remision_transportista_empresa
                                }
                                onChange={handleChange}
                                onInput={(event) => {
                                  const { selectionStart, selectionEnd } =
                                    event.target;
                                  const upperCasedValue =
                                    event.target.value.toUpperCase();
                                  event.target.value = upperCasedValue;
                                  event.target.setSelectionRange(
                                    selectionStart,
                                    selectionEnd
                                  );
                                }}
                                maxLength={20}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6">
                                Guia de Remision Transportista
                              </label>
                            </div>
                            <div className="relative z-0 w-full  group"></div>
                          </div>
                        </div>
                        <div className="Botones">
                          <div className="flex justify-end gap-x-6 ">
                            <button
                              onClick={CerraModal}
                              className="px-6 py-2 text-white bg-gradient-to-t   from-gray-400 via-gray-500 to-gray-500 hover:bg-gradient-to-br rounded-md"
                            >
                              Cancelar
                            </button>
                            <button
                              type="submit"
                              className="px-6 py-2 text-white bg-gradient-to-t   from-blue-400 via-blue-500 to-blue-500 hover:bg-gradient-to-br rounded-md"
                            >
                              Guardar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Configuracion;
