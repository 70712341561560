import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Navegacion() {
  const [permisos, setPermisos] = useState("");

  useEffect(() => {
    fetch(
      `https://sysdemo.byma-ve.com/BackendApiRest/Permisos/obtenerPermisos.php?dni_usuario=${localStorage.getItem(
        "user"
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        setPermisos(data);
      })
      .catch((error) => console.error("Error:", error));
  }, []);
  return (
    <>
      <div className="cont-navbar relative bg-white rounded-2xl h-[60px] z-0">
        <div className="navbar w-full h-full flex justify-around text-black p-4 text-center">
          <Link
            to={
              permisos.liquidacion_cliente_corporativo_permiso !== 0
                ? "/home-clientes"
                : "#"
            }
            className={`elmnts relative text-[15px] font-semibold no-underline transition-all ${
              permisos.liquidacion_cliente_corporativo_permiso !== 0
                ? "text-black hover:text-[#1058d0] "
                : "text-gray-300 cursor-not-allowed "
            }`}
          >
            Cliente Corporativo
          </Link>
          <Link
            to={
              permisos.liquidacion_cliente_pie_permiso !== 0
                ? "/home-clientepie"
                : "#"
            }
            className={`elmnts relative text-[15px] font-semibold no-underline transition-all ${
              permisos.liquidacion_cliente_pie_permiso !== 0
                ? "text-black hover:text-[#1058d0] "
                : "text-gray-300 cursor-not-allowed "
            }`}
          >
            Cliente de Pie
          </Link>
          <Link
            to={
              permisos.liquidacion_agente_permiso !== 0
                ? "/home-agente"
                : "#"
            }
            className={`elmnts relative text-[15px] font-semibold no-underline transition-all ${
              permisos.liquidacion_agente_permiso !== 0
                ? "text-black hover:text-[#1058d0] "
                : "text-gray-300 cursor-not-allowed "
            }`}
          >
            Agente
          </Link>
          <Link
            to={
              permisos.liquidacion_transportista_permiso !== 0
                ? "/home-transporte"
                : "#"
            }
            className={`elmnts relative text-[15px] font-semibold no-underline transition-all ${
              permisos.liquidacion_transportista_permiso !== 0
                ? "text-black hover:text-[#1058d0] "
                : "text-gray-300 cursor-not-allowed "
            }`}
          >
            Transportistas
          </Link>
          <Link
            to={
              permisos.liquidacion_vendedor_permiso !== 0
                ? "/home-vendedor"
                : "#"
            }
            className={`elmnts relative text-[15px] font-semibold no-underline transition-all ${
              permisos.liquidacion_vendedor_permiso !== 0
                ? "text-black hover:text-[#1058d0] "
                : "text-gray-300 cursor-not-allowed "
            }`}
          >
            Vendedor
          </Link>
        </div>
      </div>
    </>
  );
}

export default Navegacion;
