import   { useEffect, useState } from "react";
import JsBarcode from "jsbarcode";
import {
  Document,
  Image,
  Text,
  Page,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "10px",
    color: "#40403E",
    fontSize: "6px",
    rowGap: "4px",
  },
});
const Docu10x7 = ({
  datosGuia,
  datosEmpresa,
  cantidadStickers,
  idRegistro,
  fechaSeleccionada,
}) => {
  const [colorPrimario, setColorPrimario] = useState(datosEmpresa.color_empresa); // Usamos useState para 

  const generateBarcode = () => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, idRegistro, {
      format: "CODE128",
      width: 2,
      height: 100,
      displayValue: true,
      fontSize: 20,
      margin: 10,
      textMargin: 8,
    });
    return canvas.toDataURL("image/png");
  };

  const barcodeDataUrl = generateBarcode();
  return (
    <Document>
      {[...Array(Number(cantidadStickers))].map((_, index) => (
        <Page key={index} size={[283.47, 212.6]} style={styles.page}>
          {/* HEADER */}
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              columnGap: "10px",
              display: 1,
            }}
          >
            <View style={{ flex: 1, objectFit: "cover", marginTop: "" }}>
              <Image src={datosEmpresa.logo_oscuro_empresa} />
            </View>
            <View
              style={{
                flex: 1,
                // borderRadius: "8px",
                // border: "0.5px solid gray",
                paddingVertical: "1px",
                paddingHorizontal: "2px",
                rowGap: "2px",
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  columnGap: "2px",
                }}
              >
                <Text style={{}}>Ruc:</Text>
                <Text style={{}}>{datosEmpresa.ruc_empresa}</Text>
              </View>
              <View
                style={{
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  columnGap: "2px",
                }}
              >
                <Text style={{}}>Fecha:</Text>
                <Text style={{}}>{fechaSeleccionada}</Text>
              </View>

              <View
                style={{
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  columnGap: "2px",
                }}
              >
                <Text style={{}}>Ubigeo:</Text>
                <Text style={{}}>{datosGuia.ubigeo}</Text>
              </View>
            </View>

            <View
              style={{
                flex: 1,
                borderRadius: "8px",
                border: "0.5px solid gray",
                paddingVertical: "1px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <View>
                <Text style={{}}>Ruc: {datosEmpresa.ruc_empresa}</Text>
              </View>
              <View style={{ paddingVertical: "1px" }}>
                <Text
                  style={{
                    backgroundColor: colorPrimario,
                    paddingVertical: "1px",
                    fontSize: "7px",
                    color: "white",
                  }}
                >
                  Guia Tracking
                </Text>
              </View>
              <View>
                <Text style={{ fontStyle: "italic" }}>{idRegistro}</Text>
              </View>
            </View>
          </View>
          {/* DATOS DE ENVIO */}
          <View
            style={{
              width: "100%",
              rowGap: "3px",
              height: "34%",
            }}
          >
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ width: "15%", fontSize: "5px" }}>Envia:</Text>
              <Text style={{ width: "75%", fontSize: "5px" }}>
                {datosGuia.razon_social_cliente}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                marginBottom: "4px",
              }}
            >
              <Text style={{ width: "15%", fontSize: "5px" }}>Direccion:</Text>
              <Text style={{ width: "75%", lineHeight: 1.2, fontSize: "5px" }}>
                {datosGuia.direccion_cliente +
                  " / " +
                  datosGuia.DEPARTAMENTO +
                  " - " +
                  datosGuia.PROVINCIA +
                  " - " +
                  datosGuia.DESTINO}
              </Text>
            </View>
            <View
              style={{ width: "100%", borderBottom: "0.6px solid gray" }}
            ></View>
            <View
              style={{ flexDirection: "row", width: "100%", marginTop: "4px" }}
            >
              <Text style={{ width: "15%", fontSize: "5px" }}>Recibe:</Text>
              <Text style={{ width: "75%", fontSize: "5px" }}>
                {datosGuia.consignado}
              </Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ width: "15%", fontSize: "5px" }}>Direccion:</Text>
              <Text style={{ width: "75%", lineHeight: 1.2, fontSize: "5px" }}>
                {datosGuia.direccion +
                  " / " +
                  datosGuia.departamento_destino +
                  " - " +
                  datosGuia.provincia_destino +
                  " - " +
                  datosGuia.distrito_destino}
              </Text>
            </View>
          </View>
          {/* UBIGEO */}
          <View
            style={{
              flexDirection: "row",
              marginTop: "-16px",
            }}
          >
            <Text
              style={{
                backgroundColor: colorPrimario,
                borderRadius: "2px",
                paddingHorizontal: "5px",
                paddingVertical: "1px",
                color: "white",
              }}
            >
              {datosGuia.departamento_destino} / {datosGuia.provincia_destino} /{" "}
              {datosGuia.distrito_destino}
            </Text>
          </View>
          {/* CODIGO BARRA Y CHECKBOX */}
          <View
            style={{
              flexDirection: "row",
            }}
          >
            {/* BARRA */}
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Image style={{ width: 100, height: 35 }}src={barcodeDataUrl} />
            </View>
            {/* Checkbox */}
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
                marginTop: "-6px",
                marginLeft: "-18px",
              }}
            >
              <View style={{ gap: "5px" }}>
                <View
                  style={{
                    alignItems: "center",
                    columnGap: "3px",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      border: "1px solid gray",
                      width: "8px",
                      height: "8px",
                    }}
                  ></View>
                  <Text style={{}}>TITULAR</Text>
                </View>
                <View
                  style={{
                    alignItems: "center",
                    columnGap: "3px",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      border: "1px solid gray",
                      width: "8px",
                      height: "8px",
                    }}
                  ></View>
                  <Text style={{}}>EMPLEADO</Text>
                </View>
              </View>
              <View style={{ gap: "5px" }}>
                <View
                  style={{
                    flexDirection: "row",
                    columnGap: "3px",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      border: "1px solid gray",
                      width: "8px",
                      height: "8px",
                    }}
                  ></View>
                  <Text style={{}}>FAMILIA</Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    columnGap: "3px",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      border: "1px solid gray",
                      width: "8px",
                      height: "8px",
                    }}
                  ></View>
                  <Text style={{}}>VIGILANTE</Text>
                </View>
              </View>
            </View>
          </View>
          {/* CONTENEDOR PADRE DDATOS */}
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              columnGap: "5px",
            }}
          >
            <Text style={{}}>Nombres:</Text>
            <Text style={{ marginRight: "5px" }}>
              ________________________________
            </Text>
            <Text style={{}}>D.N.I: </Text>
            <Text style={{}}>_____________________________</Text>
          </View>
          <View
            style={{
              width: "100%",

              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "60%",
                columnGap: "5px",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "5px",
                }}
              >
                <Text style={{}}>Fecha: </Text>
                <Text style={{}}>_____/_____/_____</Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "5px",
                }}
              >
                <Text style={{}}>Hora: </Text>
                <Text style={{}}>______:______</Text>
              </View>
            </View>
            <View
              style={{
                width: "40%",
                columnGap: "5px",
                justifyContent: "center",
                gap: "25px",
                textAlign: "center",
                alignContent: "center",
                alignSelf: "center",
                marginTop: "6.2px",
              }}
            >
              <View style={{ justifyContent: "center" }}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    columnGap: "5px",
                  }}
                >
                  <Text style={{}}>_______________________________</Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: "5px",
                    alignItems: "center",
                    textAlign: "center",
                    alignContent: "center",
                    alignSelf: "center",
                  }}
                >
                  <Text style={{}}>Firma y/o Sello</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};
export default Docu10x7;
