// NavbarContext.js
import { createContext, useState, useEffect } from "react";

export const NavbarContext = createContext();

export function NavbarProvider({ children }) {
  const [menuIconosVisible, setMenuIconosVisible] = useState(true);
  const [abierto, setAbierto] = useState("");
  const [minimizar, setMinimizar] = useState(false);
  const [minimizar2, setminimizar2] = useState(false);
  const [minimizar3, setminimizar3] = useState(false);
  const [permisos, setPermisos] = useState("");
  const [loading, setLoading] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  // Efecto para obtener permisos desde el backend
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      fetch(
        `https://sysdemo.byma-ve.com/BackendApiRest/Permisos/obtenerPermisos.php?dni_usuario=${user}`
      )
        .then((response) => response.json())
        .then((data) => {
          setPermisos(data);
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false); // Error, detener la carga
        });
    } else {
      setLoading(false); // Sin usuario, detener la carga
    }
  }, [localStorage.getItem("user")]);

  return (
    <NavbarContext.Provider
      value={{
        menuIconosVisible,
        setMenuIconosVisible,
        abierto,
        setAbierto,
        minimizar,
        setMinimizar,
        minimizar2,
        setminimizar2,
        minimizar3,
        setminimizar3,
        isHovered,
        setIsHovered,
        permisos,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
}
