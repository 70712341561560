import   { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
function ModalCrearCliente({ modalNuevo, setModalNuevo, actualizarTabla }) {
  // Funcion Ocultar Modal
  const ocultarModal = () => {
    setModalNuevo(false);
  };

  const initialFormState = {
    dni_cliente: "",
    razon_social_cliente: "",
    representante_cliente: "",
    clave_cliente: "",
    id_vendedor_usuario_cliente: "",
    limite_credito_cliente: "",
    alerta_credito_cliente: "",
    ubigeo_cliente: "",
    direccion_cliente: "",
    referencias_cliente: "",
    contacto_cliente: "",
    telefono_cliente: "",
    email_cliente: "",
    area_cliente: "",
    logo_cliente: null,
  };

  const [formData, setFormData] = useState(initialFormState);

  // Nuevo estado para indicar el reinicio del formulario
  const [resetForm, setResetForm] = useState(false);

  const [imagenUsuario, setImagenUsuario] = useState(null);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      // Manejar la carga de la imagen
      const file = e.target.files[0];
      setImagenUsuario(file);
    } else {
      // Manejar otros campos de entrada
      setFormData({ ...formData, [name]: value });
    }
  };

  const resetFormState = () => {
    // Cambia el estado de resetForm para reiniciar el formulario
    setResetForm((prevResetForm) => !prevResetForm);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataWithImage = new FormData();
    formDataWithImage.append(
      "id_creador_cliente",
      localStorage.getItem("id_usuario")
    );
    formDataWithImage.append("dni_cliente", formData.dni_cliente);
    formDataWithImage.append(
      "razon_social_cliente",
      formData.razon_social_cliente
    );
    formDataWithImage.append(
      "representante_cliente",
      formData.representante_cliente
    );
    formDataWithImage.append("clave_cliente", formData.clave_cliente);
    formDataWithImage.append(
      "id_vendedor_usuario_cliente",
      formData.id_vendedor_usuario_cliente
    );
    formDataWithImage.append(
      "limite_credito_cliente",
      formData.limite_credito_cliente
    );
    formDataWithImage.append("alerta_credito_cliente", 0);
    formDataWithImage.append("ubigeo_cliente", formData.ubigeo_cliente);
    formDataWithImage.append("direccion_cliente", formData.direccion_cliente);
    formDataWithImage.append(
      "referencias_cliente",
      formData.referencias_cliente
    );
    formDataWithImage.append("contacto_cliente", formData.contacto_cliente);
    formDataWithImage.append("telefono_cliente", formData.telefono_cliente);
    formDataWithImage.append("email_cliente", formData.email_cliente);
    formDataWithImage.append("area_cliente", formData.area_cliente);
    formDataWithImage.append("logo_cliente", imagenUsuario);

    // ESTILOS DE PRECARGADO
    Swal.fire({
      allowOutsideClick: false,
      showConfirmButton: false,
      background: "transparent",
      html: `
      <div class="papapa"> 
        <div class="loader1"> 
        <h1 class="guardado" >Guardando...</h1>
        </div>
      
        <div class="loader2">
          <div class="justify-content-center jimu-primary-loading"></div>
        </div>
      </div>
    `,
      onBeforeOpen: () => {
        // Función que se ejecuta antes de que se abra la ventana modal
        Swal.showLoading(); // Muestra una animación de carga dentro de la ventana modal
      },
    });

    try {
      const response = await fetch(
        "https://sysdemo.byma-ve.com/BackendApiRest/Administracion/Cliente/guardar_cliente.php",
        {
          method: "POST",
          body: formDataWithImage,
        }
      );

      if (response.ok) {
        ocultarModal();
        // Muestra una alerta de éxito
        Swal.fire({
          icon: "success",
          title: "Guardado",
          text: "Los datos se han guardado correctamente.",
        });
        actualizarTabla();
        // Resetea el formulario después de la presentación exitosa
        resetFormState();
        setImagenUsuario(null);
        setDistritoSeleccionada();
        setProvinciaSeleccionada();
        setDepartamentoSeleccionado();
        setFormData(initialFormState);
      } else {
        // Verifica el error mandado por la API
        const errorResponse = await response.json();
        const errorMessage =
          errorResponse.error || "Hubo un error al intentar guardar los datos.";

        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
        });
      }
    } catch (error) {
      console.error("Error de red:", error);
      Swal.fire({
        icon: "error",
        title: "Error de red",
        text: "Hubo un problema de red al intentar guardar los datos.",
      });
    }
  };

  // Vendedores / Select
  const [vendedores, setVendedores] = useState([]);

  useEffect(() => {
    fetch(
      "https://sysdemo.byma-ve.com/BackendApiRest/Administracion/Usuario/obtener_vendedores.php"
    )
      .then((response) => response.json())
      .then((data) => {
        const transformedVendedores = data.map((vendedor) => ({
          value: vendedor.id,
          label: vendedor.colaborador_usuario,
        }));
        setVendedores(transformedVendedores);
      })
      .catch((error) => console.error("Error fetching Vendedores:", error));
  }, []);

  // Departamento - Provincia - Distrito / Select
  const [departamentos, setDepartamentos] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [distritos, setDistritos] = useState([]);
  const [departamentoSeleccionado, setDepartamentoSeleccionado] = useState("");
  const [provinciaSeleccionada, setProvinciaSeleccionada] = useState("");
  const [distritoSeleccionada, setDistritoSeleccionada] = useState("");

  useEffect(() => {
    // Obtener departamentos
    fetch(
      "https://sysdemo.byma-ve.com/BackendApiRest/Ubigeo/select_ubigeo.php?action=departamentos"
    )
      .then((response) => response.json())
      .then((data) => {
        const transformedDepartamentos = data.map((departamento) => ({
          value: departamento.id,
          label: departamento.nombre_dep,
        }));
        setDepartamentos(transformedDepartamentos);
      })
      .catch((error) => console.error("Error fetching departamentos:", error));
  }, []);

  useEffect(() => {
    if (departamentoSeleccionado) {
      // Obtener provincias por departamento
      fetch(
        `https://sysdemo.byma-ve.com/BackendApiRest/Ubigeo/select_ubigeo.php?action=provincias&id=${departamentoSeleccionado}`
      )
        .then((response) => response.json())
        .then((data) => {
          const transformedDepartamentos = data.map((provincia) => ({
            value: provincia.id,
            label: provincia.nombre_prov,
          }));
          setProvincias(transformedDepartamentos);
        })
        .catch((error) => console.error("Error fetching provincias:", error));
    }
  }, [departamentoSeleccionado]);

  useEffect(() => {
    if (provinciaSeleccionada) {
      // Obtener distritos por provincia
      fetch(
        `https://sysdemo.byma-ve.com/BackendApiRest/Ubigeo/select_ubigeo.php?action=distritos&id=${provinciaSeleccionada}`
      )
        .then((response) => response.json())
        .then((data) => {
          const transformedDepartamentos = data.map((provincia) => ({
            value: provincia.ubigeo,
            label: provincia.nombre_dist,
          }));
          setDistritos(transformedDepartamentos);
        })
        .catch((error) => console.error("Error fetching distritos:", error));
    }
  }, [provinciaSeleccionada]);

  const handleDepartamentoChange = (event) => {
    setDepartamentoSeleccionado(event.target.value);
    setProvinciaSeleccionada("");
    setDistritoSeleccionada("");
    setDistritos([]);
  };

  const handleProvinciaChange = (event) => {
    setProvinciaSeleccionada(event.target.value);
    setDistritoSeleccionada("");
  };

  const handleDistritoChange = (event) => {
    const distritoSeleccionado = event.target.value;
    setDistritoSeleccionada(distritoSeleccionado);
  };

  const customStyles2 = {
    control: (provided ) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "none",
      fontSize: "14px",
      borderRadius: "0px",
      height: "16px",
      borderBottom: "2px solid #9ca3af",
      boxShadow: "none",
      "&:active": {
        borderColor: "#0389fb ",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "10px 4px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "45px",
    }),

    menu: (provided) => ({
      ...provided,
      marginTop: "5px",
      borderRadius: "4px",
      boxShadow:
        "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    }),
    option: (provided ) => ({
      ...provided,
      fontSize: "12px",
      borderRadius: "5px",
      padding: "8px 6px",
    }),
  };

  const handleSelectChange = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : "";
    setFormData((prevValues) => ({
      ...prevValues,
      id_vendedor_usuario_cliente: value,
    }));
  };
  return (
    <>
      <div
        className={`side-panel-container ${
          modalNuevo ? "visible" : "invisible"
        } fixed pointer-events-auto left-0 top-0 right-0 h-full bg-[rgba(0,0,0,0.4)] z-10`}
      >
        <div
          className={`side-panel-cont-container ${
            modalNuevo ? "translate-x-[0%]" : "translate-x-[100%]"
          } w-[500px] h-full block absolute top-0 right-0 bottom-0 bg-slate-100 transition-transform duration-1000 `}
        >
          <div className="side-panel-content-container block absolute top-0 right-0 bottom-0 left-0 mb-6">
            <div className="side-panel-iframe relative w-full h-full overflow-y-auto ScrollTableVertical ">
              <div className="side-panel  h-full w-auto m-0 ">
                <div className="side-cont-titulo py-2 text-[25px] font-medium px-6 bg-blue-500 text-white mb-6 opacity-80">
                  <div className="side-titulo  pb-2">
                    <h1 className="side-txt">Nuevo Cliente</h1>
                  </div>
                </div>
                <div>
                  <div className="section-crm px-6">
                    <div className="card-container">
                      <form
                        className="text-black"
                        key={resetForm}
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                      >
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="dni_cliente"
                            id="dni_cliente"
                            onChange={handleInputChange}
                            className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            pattern="^[0-9]+$"
                            maxLength={11}
                            required
                          />
                          <label className="upp peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            DNI *
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="razon_social_cliente"
                            id="razon_social_cliente"
                            onChange={handleInputChange}
                            className="uppercase block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                              onInput={(event) => {
                                const { selectionStart, selectionEnd } =
                                  event.target;
                                const upperCasedValue =
                                  event.target.value.toUpperCase();
                                event.target.value = upperCasedValue;
                                event.target.setSelectionRange(
                                  selectionStart,
                                  selectionEnd
                                );
                              }}
                          />
                          <label className="upp peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            Razon social *
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="representante_cliente"
                            id="representante_cliente"
                            onChange={handleInputChange}
                            className="uppercase block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                              onInput={(event) => {
                                const { selectionStart, selectionEnd } =
                                  event.target;
                                const upperCasedValue =
                                  event.target.value.toUpperCase();
                                event.target.value = upperCasedValue;
                                event.target.setSelectionRange(
                                  selectionStart,
                                  selectionEnd
                                );
                              }}
                          />
                          <label className="upp peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            Representante *
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="clave_cliente"
                            id="clave_cliente"
                            onChange={handleInputChange}
                            className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                          />
                          <label className="upp peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            Clave *
                          </label>
                        </div>
                        {/* <div className="relative z-0 w-full mb-6 group">
                          <div
                            type="select"
                            name="id_vendedor_usuario_cliente"
                            id="id_vendedor_usuario_cliente"
                            className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            onChange={handleInputChange}
                            required
                          >
                            <SearchVendedor
                              vendedores={vendedores}
                              setSelectedVendedor={(vendedor) =>
                                setFormData({
                                  ...formData,
                                  id_vendedor_usuario_cliente: vendedor.id,
                                })
                              }
                            />
                          </div>
                          <label className="upp peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            Vendedor *
                          </label>
                        </div> */}
                        <div className="relative  w-full mb-4 group ">
                          <div className=" w-full">
                            <Select
                              name="id_vendedor_usuario_cliente"
                              id="id_vendedor_usuario_cliente"
                              placeholder="Elegir Vendedor"
                              styles={customStyles2}
                              options={vendedores}
                              className="block py-2.5 px-0 w-full text-sm"
                              classNamePrefix="react-select"
                              value={
                                formData.id_vendedor_usuario_cliente
                                  ? vendedores.find(
                                      (option) =>
                                        option.value ===
                                        formData.id_vendedor_usuario_cliente
                                    )
                                  : null
                              }
                              onChange={handleSelectChange}
                              required
                            ></Select>
                          </div>
                          <label className="upp peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            Vendedor *
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="number"
                            name="limite_credito_cliente"
                            id="limite_credito_cliente"
                            onChange={handleInputChange}
                            className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            step="0.01"
                            required
                          />
                          <label className="upp peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            Limite de Credito *
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group" hidden>
                          <input
                            type="number"
                            name="alerta_credito_cliente"
                            id="alerta_credito_cliente"
                            onChange={handleInputChange}
                            className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            step="0.01"
                          />
                          <label className="upp peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            Alerta Credito *
                          </label>
                        </div>
                        <div className="relative  w-full mb-6 group">
                          {/* <select
                            type="select"
                            name="departamento"
                            id="departamento"
                            onChange={handleDepartamentoChange}
                            className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                          >
                            <option className="bg-gray-200">
                              Elegir Departamento
                            </option>
                            {departamentos.map((departamento) => (
                              <option
                                className="bg-gray-200"
                                key={departamento.id}
                                value={departamento.id}
                              >
                                {departamento.nombre_dep}
                              </option>
                            ))}
                          </select> */}
                          <Select
                            name="departamento"
                            id="departamento"
                            placeholder="Elegir Departamento"
                            styles={customStyles2}
                            options={departamentos}
                            className="block py-2.5 px-0 w-full text-sm "
                            classNamePrefix="react-select"
                            required
                            onChange={(selectedOption) =>
                              handleDepartamentoChange({
                                target: {
                                  name: "departamento",
                                  value: selectedOption.value,
                                },
                              })
                            }
                            value={
                              departamentoSeleccionado
                                ? departamentos.find(
                                    (option) =>
                                      option.value === departamentoSeleccionado
                                  )
                                : null
                            }
                          ></Select>
                          <label className="upp peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            Departamento *
                          </label>
                        </div>

                        <div className="relative  w-full mb-6 group">
                          {/* <select
                            type="select"
                            name="provincia"
                            id="provincia"
                            onChange={handleProvinciaChange}
                            disabled={!departamentoSeleccionado}
                            className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                          >
                            <option className="bg-gray-200">
                              Elegir Provincia
                            </option>
                            {provincias.map((provincia) => (
                              <option
                                className="bg-gray-200"
                                key={provincia.id}
                                value={provincia.id}
                              >
                                {provincia.nombre_prov}
                              </option>
                            ))}
                          </select> */}
                          <Select
                            name="provincia"
                            id="provincia"
                            placeholder="Elegir Provincia"
                            styles={customStyles2}
                            options={provincias}
                            className="block py-2.5 px-0 w-full text-sm"
                            classNamePrefix="react-select"
                            value={
                              provinciaSeleccionada
                                ? provincias.find(
                                    (option) =>
                                      option.value === provinciaSeleccionada
                                  )
                                : null
                            }
                            onChange={(selectedOption) =>
                              handleProvinciaChange({
                                target: {
                                  name: "provincia",
                                  value: selectedOption.value,
                                },
                              })
                            }
                            isDisabled={!departamentoSeleccionado}
                            required
                          ></Select>
                          <label className="upp peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            Provincia *
                          </label>
                        </div>
                        <div className="relative  w-full mb-6 group">
                          {/* <select
                            type="select"
                            name="ubigeo_cliente"
                            id="ubigeo_cliente"
                            onChange={handleInputChange}
                            disabled={!provinciaSeleccionada}
                            className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                          >
                            <option className="bg-gray-200">
                              Elegir Distrito
                            </option>
                            {distritos.map((distrito) => (
                              <option
                                className="bg-gray-200"
                                key={distrito.id}
                                value={distrito.ubigeo}
                              >
                                {distrito.nombre_dist}
                              </option>
                            ))}
                          </select> */}
                          <Select
                            name="ubigeo_cliente"
                            id="ubigeo_cliente"
                            placeholder="Elegir Distrito"
                            styles={customStyles2}
                            options={distritos}
                            className="block py-2.5 px-0 w-full text-sm"
                            classNamePrefix="react-select"
                            value={
                              distritoSeleccionada
                                ? distritos.find(
                                    (option) =>
                                      option.value === distritoSeleccionada
                                  )
                                : null
                            }
                            onChange={(selectedOption) => {
                              const event = {
                                target: {
                                  name: "ubigeo_cliente",
                                  value: selectedOption.value,
                                },
                              };
                              handleInputChange(event);
                              handleDistritoChange(event);
                            }}
                            isDisabled={!provinciaSeleccionada}
                            required
                          ></Select>

                          <label className="upp peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            Distrito *
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="direccion_cliente"
                            id="direccion_cliente"
                            onChange={handleInputChange}
                            className="uppercase block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                              onInput={(event) => {
                                const { selectionStart, selectionEnd } =
                                  event.target;
                                const upperCasedValue =
                                  event.target.value.toUpperCase();
                                event.target.value = upperCasedValue;
                                event.target.setSelectionRange(
                                  selectionStart,
                                  selectionEnd
                                );
                              }}
                          />
                          <label className="upp peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            Direccion *
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="referencias_cliente"
                            id="referencias_cliente"
                            onChange={handleInputChange}
                            className="uppercase block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                              onInput={(event) => {
                                const { selectionStart, selectionEnd } =
                                  event.target;
                                const upperCasedValue =
                                  event.target.value.toUpperCase();
                                event.target.value = upperCasedValue;
                                event.target.setSelectionRange(
                                  selectionStart,
                                  selectionEnd
                                );
                              }}
                          />
                          <label className="upp peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            Referencias *
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="contacto_cliente"
                            id="contacto_cliente"
                            onChange={handleInputChange}
                            className="uppercase block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                              onInput={(event) => {
                                const { selectionStart, selectionEnd } =
                                  event.target;
                                const upperCasedValue =
                                  event.target.value.toUpperCase();
                                event.target.value = upperCasedValue;
                                event.target.setSelectionRange(
                                  selectionStart,
                                  selectionEnd
                                );
                              }}
                          />
                          <label className="upp peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            Contacto *
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="telefono_cliente"
                            id="telefono_cliente"
                            onChange={handleInputChange}
                            className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            pattern="^[0-9]+$"
                            maxLength={9}
                            required
                          />
                          <label className="upp peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            Telefono *
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="email"
                            name="email_cliente"
                            id="email_cliente"
                            onChange={handleInputChange}
                            className="uppercase block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                          />
                          <label className="upp peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            Email *
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="area_cliente"
                            id="area_cliente"
                            onChange={handleInputChange}
                            className="uppercase block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                              onInput={(event) => {
                                const { selectionStart, selectionEnd } =
                                  event.target;
                                const upperCasedValue =
                                  event.target.value.toUpperCase();
                                event.target.value = upperCasedValue;
                                event.target.setSelectionRange(
                                  selectionStart,
                                  selectionEnd
                                );
                              }}
                          />
                          <label className="upp peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            Area *
                          </label>
                        </div>
                        <div className="flex items-center justify-end rounded-b mt-7">
                          <button
                            type="submit"
                            className="text-white bg-gradient-to-t from-blue-400 via-blue-500 to-blue-500 hover:bg-gradient-to-br focus:ring-0 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 rounded-lg text-sm text-center font-medium px-5 py-2.5 mr-4"
                          >
                            Guardar
                          </button>
                          <button
                            onClick={ocultarModal}
                            type="button"
                            className="text-white bg-gradient-to-t from-gray-400 via-gray-500 to-gray-500 hover:bg-gradient-to-br focus:ring-0 focus:outline-none focus:ring-gray-300  rounded-lg text-sm text-center font-medium px-5 py-2.5 mr-4"
                          >
                            Cancelar
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalCrearCliente;
