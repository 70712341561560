import Fondo1 from "../Static/Img_Pred/Fondo3.webp";
import Fondo2 from "../Static/Img_Pred/Fondo1.webp";
import Fondo3 from "../Static/Img_Pred/Fondo2.webp";
import Fondo4 from "../Static/Img_Pred/Fondo4.webp";
import Fondo5 from "../Static/Img_Pred/Fondo5.webp";
import Fondo6 from "../Static/Img_Pred/Fondo6.webp";
import Fondo7 from "../Static/Img_Pred/Fondo7.webp";
import Fondo8 from "../Static/Img_Pred/Fondo8.webp";

export const imageData = [
  Fondo1,
  Fondo2,
  Fondo3,
  Fondo4,
  Fondo5,
  Fondo6,
  Fondo7,
  Fondo8,
];
