import { useState, useEffect } from "react";
import Select from "react-select";

function DatosRemitente({
  setUbigeoCliente,
  setDniRucCliente,
  dniRucCliente,
  setRazonSocialCliente,
  razonSocialCliente,
}) {
  const [clienteElegido, setClienteElegido] = useState("");
  const [optionsClientes, setOptionsClientes] = useState([]);
  const [optionsAreas, setOptionsAreas] = useState([""]);
  // Cargar los datos del cliente al montar el componente
  useEffect(() => {
    fetch(
      "https://sysdemo.byma-ve.com/BackendApiRest/Administracion/Cliente/obtenerClientesTarifarios.php"
    )
      .then((response) => response.json())
      .then((data) => {
        const formattedOptions = data.map((option) => ({
          value: option.id,
          label: option.razon_social_cliente,
        }));
        setOptionsClientes(formattedOptions);
      })
      .catch((error) => {
        console.error("Error al obtener las opciones:", error);
      });
  }, []);

  // Cargar los datos de las áreas cuando se elige un cliente
  useEffect(() => {
    if (clienteElegido) {
      fetch(
        `https://sysdemo.byma-ve.com/BackendApiRest/Administracion/Area/obtenerAreasTarifario.php?id_cliente=${clienteElegido}`
      )
        .then((response) => response.json())
        .then((data) => {
          const formattedAreas = data.map((area) => ({
            value: area.id,
            label: area.nombre_area,
          }));
          setOptionsAreas(formattedAreas);
        })
        .catch((error) => {
          console.error("Error al obtener las áreas:", error);
        });
    }
  }, [clienteElegido]);

  const handleDniRucChange = (event) => {
    setDniRucCliente(event.target.value);
  };

  const handleRazonSocialChange = (event) => {
    setRazonSocialCliente(event.target.value);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "30px",
      height: "10px",
      fontSize: "16px",
      borderRadius: "10px",
      backgroundColor: "transparent",
      border: "none",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "5px",
      fontSize: "14px",
      margin: "6px 0",
      padding: "8px 0px",
    }),
    option: (provided) => ({
      ...provided,
      borderRadius: "5px",
      padding: "4px 12px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 8px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none", // Oculta el indicador
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none", // Oculta la barrita al lado del indicador
    }),
  };

  // Departamento - Provincia - Distrito / Select
  const [departamentos, setDepartamentos] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [distritos, setDistritos] = useState([]);
  const [departamentoSeleccionado, setDepartamentoSeleccionado] = useState("");
  const [provinciaSeleccionada, setProvinciaSeleccionada] = useState("");
  const [distritoSeleccionada, setDistritoSeleccionada] = useState("");

  useEffect(() => {
    // Obtener departamentos
    fetch(
      "https://sysdemo.byma-ve.com/BackendApiRest/Ubigeo/select_ubigeo.php?action=departamentos"
    )
      .then((response) => response.json())
      .then((data) => {
        const transformedDepartamentos = data.map((departamento) => ({
          value: departamento.id,
          label: departamento.nombre_dep,
        }));
        setDepartamentos(transformedDepartamentos);
      })
      .catch((error) => console.error("Error fetching departamentos:", error));
  }, []);

  useEffect(() => {
    if (departamentoSeleccionado) {
      // Obtener provincias por departamento
      fetch(
        `https://sysdemo.byma-ve.com/BackendApiRest/Ubigeo/select_ubigeo.php?action=provincias&id=${departamentoSeleccionado}`
      )
        .then((response) => response.json())
        .then((data) => {
          const transformedDepartamentos = data.map((provincia) => ({
            value: provincia.id,
            label: provincia.nombre_prov,
          }));
          setProvincias(transformedDepartamentos);
        })
        .catch((error) => console.error("Error fetching provincias:", error));
    }
  }, [departamentoSeleccionado]);

  useEffect(() => {
    if (provinciaSeleccionada) {
      // Obtener distritos por provincia
      fetch(
        `https://sysdemo.byma-ve.com/BackendApiRest/Ubigeo/select_ubigeo.php?action=distritos&id=${provinciaSeleccionada}`
      )
        .then((response) => response.json())
        .then((data) => {
          const transformedDepartamentos = data.map((provincia) => ({
            value: provincia.ubigeo,
            label: provincia.nombre_dist,
          }));
          setDistritos(transformedDepartamentos);
        })
        .catch((error) => console.error("Error fetching distritos:", error));
    }
  }, [provinciaSeleccionada]);

  const handleDepartamentoChange = (event) => {
    setDepartamentoSeleccionado(event.target.value);
    setProvinciaSeleccionada("");
    setDistritoSeleccionada("");
    setUbigeoCliente("");
    setDistritos([]);
  };

  const handleProvinciaChange = (event) => {
    setProvinciaSeleccionada(event.target.value);
    setDistritoSeleccionada("");
    setUbigeoCliente("");
  };

  const handleDistritoChange = (event) => {
    const distritoSeleccionado = event.target.value;
    setDistritoSeleccionada(distritoSeleccionado);
    setUbigeoCliente(distritoSeleccionado);
  };

  return (
    <>
      <div className="datos-remitente rounded-md ">
        <div className="side-panel bg-[#fff] rounded-2xl">
          <div className="side-cont-titulo  text-[18px] font-semibold text-[#535c69]">
            <div className="side-titulo ">
              <h1 className="side-txt text-white py-2 px-5  bg-blue-400 rounded-t-xl">
                Datos del Cliente
              </h1>
            </div>
          </div>
          <div className="section-crm ">
            <div className="card-container">
              <div className=" px-5">
                <div className="relative z-0 w-full my-5  group">
                  <input
                    type="text"
                    name="dni_ruc_cliente_punto_venta"
                    id="dni_ruc_cliente_punto_venta"
                    className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                    value={dniRucCliente}
                    onChange={handleDniRucChange}
                    maxLength={11}
                  />
                  <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                    DNI/RUC
                  </label>
                </div>

                <div className="relative z-0 w-full my-5  group">
                  <input
                    type="text"
                    name="razon_social_cliente_punto_venta"
                    id="razon_social_cliente_punto_venta"
                    onChange={handleRazonSocialChange}
                    value={razonSocialCliente}
                    className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                    onInput={(event) => {
                      const { selectionStart, selectionEnd } = event.target;
                      const upperCasedValue = event.target.value.toUpperCase();
                      event.target.value = upperCasedValue;
                      event.target.setSelectionRange(
                        selectionStart,
                        selectionEnd
                      );
                    }}
                  />
                  <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                    Razon Social
                  </label>
                </div>
                <div className="cont-elegir-cliente mt-2 flex flex-col w-full">
                  <label>Elegir Departamento:</label>
                  <Select
                    styles={customStyles}
                    placeholder="Departamento"
                    className="react-select-container w-full  my-2 -py-4 bg-gray-100  rounded-lg focus:outline-none focus:ring-0  focus:border-blue-500 focus:shadow-md"
                    classNamePrefix="react-select"
                    options={departamentos}
                    onChange={(selectedOption) =>
                      handleDepartamentoChange({
                        target: {
                          name: "departamento",
                          value: selectedOption.value,
                        },
                      })
                    }
                    required
                    value={
                      departamentoSeleccionado
                        ? departamentos.find(
                            (option) =>
                              option.value === departamentoSeleccionado
                          )
                        : null
                    }
                  />
                </div>
                <div className="cont-elegir-cliente mt-2 flex flex-col w-full">
                  <label>Elegir Provincia:</label>
                  <Select
                    styles={customStyles}
                    placeholder="Provincia"
                    className="react-select-container w-full  my-2 -py-4 bg-gray-100  rounded-lg focus:outline-none focus:ring-0  focus:border-blue-500 focus:shadow-md"
                    classNamePrefix="react-select"
                    options={provincias}
                    onChange={(selectedOption) =>
                      handleProvinciaChange({
                        target: {
                          name: "provincia",
                          value: selectedOption.value,
                        },
                      })
                    }
                    name="provincia"
                    id="provincia"
                    isDisabled={!departamentoSeleccionado}
                    value={
                      provinciaSeleccionada
                        ? provincias.find(
                            (option) => option.value === provinciaSeleccionada
                          )
                        : null
                    }
                    required
                  />
                </div>
                <div className="cont-elegir-cliente mt-2 flex flex-col w-full">
                  <label>Elegir Distrito:</label>
                  <Select
                    styles={customStyles}
                    placeholder="Distrito"
                    className="react-select-container w-full mb-6  my-2 -py-4 bg-gray-100  rounded-lg focus:outline-none focus:ring-0  focus:border-blue-500 focus:shadow-md"
                    classNamePrefix="react-select"
                    isDisabled={!provinciaSeleccionada}
                    options={distritos}
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "ubigeo_cliente_punto_venta",
                          value: selectedOption.value,
                        },
                      };
                      //handleChange(event);
                      handleDistritoChange(event);
                    }}
                    value={
                      distritoSeleccionada
                        ? distritos.find(
                            (option) => option.value === distritoSeleccionada
                          )
                        : null
                    }
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DatosRemitente;
