import   { useEffect, useState } from "react";
import JsBarcode from "jsbarcode";
import {
  Document,
  Image,
  Text,
  Page,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import Icon from "../../../../Static/Img_Pred/IconDelivery.png";
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "20px",
    color: "#40403E",
    fontSize: "7px",
    rowGap: "5px",
  },
});

const Docu9x21 = ({
  datosGuia,
  datosEmpresa,
  cantidadStickers,
  idRegistro,
  fechaSeleccionada,
}) => {
  const [colorPrimario, setColorPrimario] = useState(datosEmpresa.color_empresa); // Usamos useState para colorPrimario

  const generateBarcode = () => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, idRegistro, {
      format: "CODE128",
      width: 2,
      height: 100,
      displayValue: true,
      fontSize: 20,
      margin: 10,
      textMargin: 8,
    });
    return canvas.toDataURL("image/png");
  };

  const barcodeDataUrl = generateBarcode();
  return (
    <Document>
      {[...Array(Number(cantidadStickers))].map((_, index) => (
        <Page size={[595.28, 280.08]} style={styles.page}>
          {/* HEADER */}
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              columnGap: "10px",
              display: 1,
            }}
          >
            <View style={{ flex: 1, objectFit: "cover", marginTop: "-10px" }}>
              <Image src={datosEmpresa.logo_oscuro_empresa} />
            </View>
            <View
              style={{
                flex: 2,
                fontSize: "7px",
                color: "black",
                textAlign: "right",
              }}
            >
              <Text>RUC: {datosEmpresa.ruc_empresa}</Text>
              <Text>{datosEmpresa.direccion_empresa}</Text>
              <Text>
                Servicio al cliente: (51){datosEmpresa.telefono_empresa}
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                alignItems: "flex-start",
              }}
            >
              <View
                style={{
                  border: `1px solid ${colorPrimario}`,
                  borderRadius: "2px",
                  padding: "3px",
                  backgroundColor: colorPrimario,
                  color: "white",
                }}
              >
                <Text> Canal de Operaciones</Text>
              </View>
              <View
                style={{
                  textAlign: "left",
                  marginTop: "5px",
                  marginLeft: "9px",
                }}
              >
                <Text> Fecha: {fechaSeleccionada}</Text>
              </View>
            </View>
            <View
              style={{
                flex: 1,
                borderRadius: "10px",
                marginTop: "-6px",
                border: "1px solid gray",
                paddingVertical: "4px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <View>
                <Text style={{}}>Ruc: {datosEmpresa.ruc_empresa}</Text>
              </View>
              <View style={{ paddingVertical: "2px" }}>
                <Text
                  style={{
                    backgroundColor: colorPrimario,
                    fontWeight: "ultrabold",
                    paddingVertical: "1px",
                    fontSize: "10px",
                    color: "white",
                  }}
                >
                  Guia Tracking
                </Text>
              </View>
              <View>
                <Text style={{ fontStyle: "italic" }}>{idRegistro}</Text>
              </View>
            </View>
          </View>
          {/* ORDEN SERVICIO */}
          <View
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <View style={{ flex: 1, flexDirection: "row" }}>
              <Text style={{ color: "black", marginRight: "2px" }}>
                Guia Madre:
              </Text>
              <Text style={{}}>{datosGuia.id_orden_servicio}</Text>
            </View>

            <View
              style={{
                textAlign: "right",
                flexDirection: "row",
                alignItems: "flex-end",
                alignSelf: "flex-end",
                columnGap: "10px",
              }}
            >
              <View
                style={{
                  textAlign: "right",
                  flexDirection: "row",
                  columnGap: "3px",
                }}
              >
                <Text style={{ color: "black" }}>Destino:</Text>
                <Text style={{}}>{datosGuia.distrito_destino}</Text>
              </View>
              <View
                style={{
                  textAlign: "right",
                  alignItems: "flex-end",
                  flexDirection: "row",
                  columnGap: "3px",
                }}
              >
                <Text style={{ color: "black" }}>Provincia:</Text>
                <Text style={{}}>{datosGuia.provincia_destino}</Text>
              </View>
            </View>
          </View>
          {/* TITULOS Remitente y destinatario */}
          <View
            style={{
              flexDirection: "row",
              display: "flex",
              border: `1px solid ${colorPrimario}`,
              borderRadius: "1px",
              padding: "1px",
              alignContent: "center",
              backgroundColor: colorPrimario,
            }}
          >
            <View
              style={{
                flex: 3,
                flexDirection: "row",
                paddingHorizontal: "1px",
              }}
            >
              <Text style={{ color: "white" }}>REMITENTE</Text>
            </View>
            <View
              style={{
                flex: 3,
                flexDirection: "row",
                paddingHorizontal: "1px",
                marginLeft: "3px",
              }}
            >
              <Text style={{ color: "white" }}>DESTINATARIO</Text>
            </View>
          </View>
          {/* Remitente y destinatario */}
          <View
            style={{
              flexDirection: "row",

              marginTop: "-5px",
              display: "flex",
              paddingHorizontal: "0px",
            }}
          >
            <View
              style={{
                flex: 3,
                borderRight: `1px solid ${colorPrimario}`,
                alignContent: "center",
                paddingLeft: "2px",
                paddingRight: "4px",
                rowGap: "3px",
              }}
            >
              {/* NOMBRE Y RUC */}
              <View
                style={{
                  flexDirection: "row",

                  marginTop: "2px",
                }}
              >
                <View style={{ flexDirection: "row", width: "73%" }}>
                  <Text
                    style={{
                      color: "black",
                      width: "14.5%",
                      marginRight: "14.5px",
                    }}
                  >
                    Nombre:
                  </Text>
                  <Text style={{}}>{datosGuia.razon_social_cliente}</Text>
                </View>
                <View style={{ flexDirection: "row", width: "25%" }}>
                  <Text style={{ color: "black", marginRight: "3px" }}>
                    RUC/DNI:
                  </Text>
                  <Text style={{}}>{datosGuia.dni_cliente}</Text>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                <View style={{ width: "16%" }}>
                  <Text style={{ color: "black" }}>Direccion:</Text>
                </View>
                <View style={{ width: "84%" }}>
                  <Text style={{ lineHeight: 1.2 }}>
                    {datosGuia.direccion_cliente +
                      " / " +
                      datosGuia.DEPARTAMENTO +
                      " - " +
                      datosGuia.PROVINCIA +
                      " - " +
                      datosGuia.DESTINO}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                <View style={{ width: "16%" }}>
                  <Text style={{ color: "black" }}>Teléfono:</Text>
                </View>
                <View style={{ width: "84%" }}>
                  <Text style={{}}>{datosGuia.telefono_cliente}</Text>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                <View style={{ width: "16%" }}>
                  <Text style={{ color: "black" }}>Correo:</Text>
                </View>
                <View style={{ width: "84%" }}>
                  <Text style={{}}>{datosGuia.telefono_cliente}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                flex: 3,
                alignContent: "center",
                rowGap: "3px",
                paddingLeft: "3px",
                paddingRight: "4px",
              }}
            >
              <View style={{ flexDirection: "row", marginTop: "2px" }}>
                <View style={{ width: "16%" }}>
                  <Text style={{ color: "black" }}>Nombre: </Text>
                </View>
                <View style={{ width: "84%" }}>
                  <Text style={{}}>{datosGuia.consignado}</Text>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                <View style={{ width: "16%" }}>
                  <Text style={{ color: "black" }}>Direccion: </Text>
                </View>
                <View style={{ width: "84%" }}>
                  <Text style={{ lineHeight: 1.5 }}>
                    {datosGuia.direccion +
                      " / " +
                      datosGuia.departamento_destino +
                      " - " +
                      datosGuia.provincia_destino +
                      " - " +
                      datosGuia.distrito_destino}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                <View style={{ width: "16%" }}>
                  <Text style={{ color: "black" }}>Teléfono: </Text>
                </View>
                <View style={{ width: "84%" }}>
                  <Text style={{}}>{datosGuia.telefono}</Text>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                <View style={{ width: "16%" }}>
                  <Text style={{ color: "black" }}>Ruc/Dni: </Text>
                </View>
                <View style={{ width: "84%" }}>
                  <Text style={{}}>{datosGuia.dni_ruc}</Text>
                </View>
              </View>
            </View>
          </View>
          {/* Devolucion o envio */}
          <View
            style={{
              flexDirection: "row",
              display: "flex",
              alignContent: "center",
            }}
          >
            <View
              style={{
                flex: 3,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  borderRadius: "3px",
                  backgroundColor: colorPrimario,
                  color: "white",
                  padding: "2px",
                }}
              >
                <Text style={{}}>Causal de devolucion</Text>
              </View>
            </View>

            <View
              style={{
                flex: 3,
                flexDirection: "row",
                paddingHorizontal: "1px",
                marginLeft: "3px",
              }}
            >
              <View
                style={{
                  borderRadius: "3px",
                  backgroundColor: colorPrimario,
                  color: "white",
                  padding: "2px",
                }}
              >
                <Text style={{}}>Caracteristicas fisicas de envio</Text>
              </View>
            </View>
          </View>
          {/* DATOS CHECKBOKX */}
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                flex: 3,
                flexDirection: "row",
                paddingHorizontal: "1px",
              }}
            >
              {/* CHECKBOXS */}
              <View
                style={{
                  width: "50%",
                  rowGap: "4px",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    columnGap: "15px",
                  }}
                >
                  <View
                    style={{
                      width: "80px",
                      paddingLeft: "2px",
                    }}
                  >
                    <Text>Direccion Incorrecta</Text>
                  </View>
                  <View
                    style={{
                      border: " 1px solid gray",
                      width: "8px",
                      height: "8px",
                      marginLeft: "-10px",
                    }}
                  ></View>
                  <View
                    style={{
                      border: " 1px solid gray",
                      width: "8px",
                      height: "8px",
                    }}
                  ></View>
                  <View
                    style={{
                      border: " 1px solid gray",
                      width: "8px",
                      height: "8px",
                    }}
                  ></View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    columnGap: "15px",
                  }}
                >
                  <View
                    style={{
                      width: "80px",
                      paddingLeft: "2px",
                    }}
                  >
                    <Text>Telefono Apagado</Text>
                  </View>
                  <View
                    style={{
                      border: " 1px solid gray",
                      width: "8px",
                      height: "8px",
                      marginLeft: "-10px",
                    }}
                  ></View>
                  <View
                    style={{
                      border: " 1px solid gray",
                      width: "8px",
                      height: "8px",
                    }}
                  ></View>
                  <View
                    style={{
                      border: " 1px solid gray",
                      width: "8px",
                      height: "8px",
                    }}
                  ></View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    columnGap: "15px",
                  }}
                >
                  <View
                    style={{
                      paddingLeft: "2px",
                      width: "80px",
                    }}
                  >
                    <Text>Zona no accesible</Text>
                  </View>
                  <View
                    style={{
                      border: " 1px solid gray",
                      width: "8px",
                      height: "8px",
                      marginLeft: "-10px",
                    }}
                  ></View>
                  <View
                    style={{
                      border: " 1px solid gray",
                      width: "8px",
                      height: "8px",
                    }}
                  ></View>
                  <View
                    style={{
                      border: " 1px solid gray",
                      width: "8px",
                      height: "8px",
                    }}
                  ></View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    columnGap: "15px",
                  }}
                >
                  <View
                    style={{
                      paddingLeft: "2px",
                      width: "80px",
                    }}
                  >
                    <Text>Cliente de viaje</Text>
                  </View>
                  <View
                    style={{
                      border: " 1px solid gray",
                      width: "8px",
                      height: "8px",
                      marginLeft: "-10px",
                    }}
                  ></View>
                  <View
                    style={{
                      border: " 1px solid gray",
                      width: "8px",
                      height: "8px",
                    }}
                  ></View>
                  <View
                    style={{
                      border: " 1px solid gray",
                      width: "8px",
                      height: "8px",
                    }}
                  ></View>
                </View>
              </View>
              {/* QR Y BARRA */}
              <View
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  marginTop: "-13px",
                  right: "20px",
                  position: "absolute",
                }}
              >
                <View style={{ objectFit: "cover", width: "60px" }}>
                  <Image src={Icon} />
                </View>
              </View>
            </View>
            <View
              style={{
                flex: 3,
                paddingHorizontal: "1px",
                rowGap: "4px",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  rowGap: "4px",
                  flex: 1,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    paddingLeft: "4px",
                  }}
                >
                  <View
                    style={{
                      width: "40px",
                    }}
                  >
                    <Text>Cantidad:</Text>
                  </View>
                  <Text style={{}}>{datosGuia.cantidad_mercancia}</Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    paddingLeft: "4px",
                  }}
                >
                  <View
                    style={{
                      width: "40px",
                    }}
                  >
                    <Text>Peso(Kg):</Text>
                  </View>
                  <Text style={{}}>
                    {parseFloat(datosGuia.peso_mercancia).toFixed(2)}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    paddingLeft: "4px",
                  }}
                >
                  <View
                    style={{
                      width: "40px",
                    }}
                  >
                    <Text>Peso(Vol):</Text>
                  </View>
                  <Text style={{}}>
                    {parseFloat(datosGuia.total_peso_volumen).toFixed(2)}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    paddingLeft: "4px",
                  }}
                >
                  <View
                    style={{
                      width: "40px",
                    }}
                  >
                    <Text>Piezas:</Text>
                  </View>
                  <Text style={{}}>{cantidadStickers}</Text>
                </View>
              </View>
              <View
                style={{
                  flex: 1.4,
                  rowGap: "4px",
                  textAlign: "center",
                  alignItems: "center",
                  width: "100%",
                  border: "1px solid gray",
                  marginTop: "-17px",
                  marginLeft: "-10px",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    textAlign: "center",
                    alignContent: "center",
                  }}
                >
                  <View
                    style={{
                      textAlign: "center",
                      borderBottom: "1px solid gray",
                      width: "100%",
                    }}
                  >
                    <Text>Contenido de mercancia</Text>
                  </View>
                  <View
                    style={{
                      borderTop: "1px solid gray",
                    }}
                  ></View>
                </View>
              </View>
            </View>
          </View>
          {/* FIRMA */}
          <View
            style={{
              flexDirection: "row",
              columnGap: "10px",
              alignItems: "center",
              marginLeft: "10px",
              marginTop: "13px",
            }}
          >
            <View
              style={{
                alignItems: "center",
                marginLeft: "-6px",
              }}
            >
              <Text>___________________________________________________</Text>
              <Text>Recepcion de envio| nombre | sello | firma</Text>
            </View>
            <View
              style={{
                columnGap: "5px",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: "1px solid gray",
                }}
              >
                <View
                  style={{
                    borderLeft: "1px solid gray",
                    borderRight: "1px solid gray",
                    borderTop: "1px solid gray",
                    paddingHorizontal: "5px",
                    paddingTop: "3px",
                  }}
                >
                  {" "}
                  <Text style={{ color: "white" }}>Caja1</Text>
                </View>
                <View
                  style={{
                    borderRight: "1px solid gray",
                    borderTop: "1px solid gray",
                    paddingHorizontal: "5px",
                    paddingTop: "3px",
                  }}
                >
                  {" "}
                  <Text style={{ color: "white" }}>Caja2</Text>
                </View>
                <View
                  style={{
                    borderRight: "1px solid gray",
                    borderTop: "1px solid gray",
                    paddingHorizontal: "5px",
                    paddingTop: "3px",
                  }}
                >
                  {" "}
                  <Text style={{ color: "white" }}>Caja3</Text>
                </View>
                <View
                  style={{
                    borderRight: "1px solid gray",
                    borderTop: "1px solid gray",
                    paddingHorizontal: "5px",
                    paddingTop: "3px",
                  }}
                >
                  {" "}
                  <Text style={{ color: "white" }}>Caja3</Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  columnGap: "16px",
                  marginLeft: "10px",
                }}
              >
                <View
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <Text>Dia</Text>
                </View>
                <View style={{ marginLeft: "1px" }}>
                  {" "}
                  <Text>Mes</Text>
                </View>
                <View style={{ marginLeft: "2px" }}>
                  {" "}
                  <Text>Año</Text>
                </View>
                <View style={{ marginLeft: "2px" }}>
                  {" "}
                  <Text>Hora</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                marginLeft: "80px",
              }}
            >
              <View
                style={{
                  objectFit: "cover",
                  width: "100px",
                  height: "40px",
                  marginTop: "-20px",
                  position: "absolute",
                }}
              >
                <Image
                  src={barcodeDataUrl}
                  style={{ width: 100, height: 45 }}
                />
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};
export default Docu9x21;
