import React from "react";
import {
  Document,
  Image,
  Text,
  Page,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

import Logo from "../../../../Static/Img_Pred/Logopdf.png";

const styles = StyleSheet.create({
  page: {
    padding: "20px 20px",
    alignItems: "center",
    rowGap: "7px",
    fontSize: "8px",
    paddingBottom: "60px", // Espacio para el pie de página
    // color: "#5D5D5A",
    color: "#40403E",
    position: "relative",
  },
  footer: {
    position: "absolute",
    bottom: "15px", // Puedes ajustar este valor según sea necesario
    left: 0,
    right: 0,
    textAlign: "right",
    fontSize: 7,
  },
  footerpage: {
    position: "absolute",
    bottom: "15px", // Puedes ajustar este valor según sea necesario
    left: 0,
    right: 30,
    textAlign: "right",
    fontSize: 7,
  },
  table: {
    width: "100%",
    fontSize: "10px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column",
  },
  tableContainer: {
    width: "100%",
  },
});
const DocuPDFDespacho = ({ datosSeleccionados, datosEmpresa, color }) => {
  let totalPiezas = 0;
  let totalPeso = 0;
  let totalPesoVolumen = 0;
  return (
    <Document
      author="Danfer"
      title="Despacho"
      subject="Asunto del PDF"
      keywords="react, pdf, ejemplo"
    >
      {/* Configurar el formato del PDF en tipo TICKET */}

      <Page size={"A4"} style={styles.page}>
        {/* HEADER  */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignContent: "center",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <View style={{ width: "23%" }}>
            <Image src={datosEmpresa.logo_oscuro_empresa} />
          </View>
          <View
            style={{
              width: "46%",
              fontSize: "9px",
              textAlign: "left",
              lineHeight: "1.5px",
            }}
          >
            <Text
              style={{
                color: "black",
                fontSize: "8px",
              }}
            >
              {datosEmpresa.razon_social_empresa}
            </Text>
            <Text>
              {datosEmpresa.direccion_empresa +
                " NRO. " +
                datosEmpresa.numero_empresa +
                " / " +
                datosEmpresa.departamento +
                " - " +
                datosEmpresa.provincia +
                " - " +
                datosEmpresa.distrito}
            </Text>
          </View>
          <View
            style={{
              width: "31%",
              borderRadius: "10px",
              border: "1px solid gray",
              paddingVertical: "10px",
              textAlign: "center",
              display: "flex",
            }}
          >
            <View>
              <Text style={{ fontSize: "11px" }}>
                Fecha: {datosSeleccionados.manifiesto_general.fecha_creado}
              </Text>
            </View>
            <View style={{ paddingVertical: "5px" }}>
              <Text
                style={{
                  backgroundColor: color,
                  fontWeight: "ultrabold",
                  paddingVertical: "2px",
                  fontSize: "12px",
                  color: "white",
                }}
              >
                MANIFIESTO DE SALIDA
              </Text>
            </View>
            <View>
              <Text style={{ fontSize: "11px", fontStyle: "italic" }}>
                NRO.{" "}
                {
                  datosSeleccionados.manifiesto_general
                    .id_num_manifiesto_despacho
                }
              </Text>
            </View>
          </View>
        </View>
        {/* FECHAS EMISION Y TRASLADO */}
        <View
          style={{
            width: "100%",
            rowGap: "5px",
            border: "1px solid gray",
            borderRadius: "5px",
            flexDirection: "row",
          }}
          wrap={false}
        >
          <View
            style={{
              borderRight: "1px solid gray",
              width: "50%",
              alignItems: "center",
              paddingVertical: "1px",
            }}
          >
            <Text>
              Origen:{" "}
              {datosSeleccionados.manifiesto_general.sucursal_usuario}
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              alignItems: "center",
              paddingVertical: "1px",
            }}
          >
            <Text>
              Destino: {datosSeleccionados.manifiesto_general.destino_llegada}
            </Text>
          </View>
        </View>

        {/* TABLA DETALLE DE FACTURA */}
        <View style={styles.tableContainer}>
          <View
            style={{
              ...styles.table,

              border: "1px solid gray",
              borderRadius: "5px",
              fontSize: "7px",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                backgroundColor: color,
                borderTopRightRadius: "4px",
                borderTopLeftRadius: "4px",
                borderBottom: "1px solid gray",
              }}
            >
              <View
                style={{
                  width: "5%",
                  textAlign: "center",
                  fontWeight: "800",
                  textTransform: "uppercase",
                  color: "white",
                  borderRight: "1px solid gray",
                  padding: "3px",
                }}
              >
                <Text>IT.</Text>
              </View>

              <View
                style={{
                  width: "20%",
                  textAlign: "center",
                  fontWeight: "800",
                  textTransform: "uppercase",
                  color: "white",
                  borderRight: "1px solid gray",
                  padding: "3px",
                }}
              >
                <Text>Destino</Text>
              </View>
              <View
                style={{
                  width: "5%",
                  textAlign: "center",
                  fontWeight: "800",
                  textTransform: "uppercase",
                  color: "white",
                  borderRight: "1px solid gray",
                  padding: "3px",
                }}
              >
                <Text>Pzas.</Text>
              </View>
              <View
                style={{
                  width: "7%",
                  textAlign: "center",
                  fontWeight: "800",
                  textTransform: "uppercase",
                  color: "white",
                  borderRight: "1px solid gray",
                  padding: "3px",
                }}
              >
                <Text>Peso</Text>
              </View>
              <View
                style={{
                  width: "7%",
                  textAlign: "center",
                  fontWeight: "800",
                  textTransform: "uppercase",
                  color: "white",
                  borderRight: "1px solid gray",
                  padding: "3px",
                }}
              >
                <Text>P. Vol.</Text>
              </View>
              <View
                style={{
                  width: "35%",
                  textAlign: "center",
                  fontWeight: "800",
                  textTransform: "uppercase",
                  color: "white",
                  borderRight: "1px solid gray",
                  padding: "3px",
                }}
              >
                <Text>Consignado</Text>
              </View>
              <View
                style={{
                  width: "21%",
                  textAlign: "center",
                  fontWeight: "800",
                  textTransform: "uppercase",
                  color: "white",
                  padding: "3px",
                }}
              >
                <Text>Agente</Text>
              </View>
            </View>
            {datosSeleccionados.manifiesto_destinos.map((el, index) => (
              <View
                key={index}
                style={{
                  flexDirection: "row",
                  borderBottom:
                    index === datosSeleccionados.manifiesto_destinos.length - 1
                      ? "none"
                      : "1px solid gray", // Evitar borderBottom en la última fila
                  color: "#40403E",
                  display: "flex",
                  alignContent: "center",
                }}
                minPresenceAhead={100} // Establece un umbral mínimo para evitar que se recorte
                wrap={false}
              >
                {(totalPiezas += parseInt(el.piezas_registro_carga, 10) || 0)}
                {(totalPeso += parseInt(el.peso_masa_envio, 10))}
                {(totalPesoVolumen += parseFloat(el.peso_volumen_envio))}

                <View
                  style={{
                    width: "5%",
                    whiteSpace: "nowrap",
                    borderRight: "1px solid gray",
                    padding: "3px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ textAlign: "center" }}>{index + 1}</Text>
                </View>

                <View
                  style={{
                    width: "20%",
                    borderRight: "1px solid gray",
                    padding: "3px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ textAlign: "center" }}>
                    {el.destino_llegada}
                  </Text>
                </View>
                <View
                  style={{
                    width: "5%",
                    borderRight: "1px solid gray",
                    padding: "3px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ textAlign: "center" }}>
                    {el.piezas_registro_carga}
                  </Text>
                </View>
                <View
                  style={{
                    width: "7%",
                    borderRight: "1px solid gray",
                    padding: "3px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ textAlign: "center" }}>
                    {el.peso_masa_envio}
                  </Text>
                </View>
                <View
                  style={{
                    width: "7%",
                    borderRight: "1px solid gray",
                    padding: "3px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ textAlign: "center" }}>
                    {el.peso_volumen_envio}
                  </Text>
                </View>
                <View
                  style={{
                    width: "35%",
                    borderRight: "1px solid gray",
                    padding: "3px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ textAlign: "center" }}>
                    {el.nombre_consignado}
                  </Text>
                </View>
                <View
                  style={{
                    width: "21%",
                    padding: "3px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ textAlign: "center" }}>
                    {el.razon_social_proveedor}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        {/* docs RELACIONADOS */}
        <View style={{ width: "100%", rowGap: "5px" }} wrap={false}>
          <View
            style={{
              width: "100%",
              fontSize: "7px",
              rowGap: "5px",
            }}
            wrap={false}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View style={{ rowGap: "10px", width: "75%" }}>
                {/* DOCS. RELACIONADOS */}
                <View
                  style={{
                    width: "100%",
                    fontSize: "8px",
                    flexDirection: "row",
                    columnGap: "10px",
                  }}
                >
                  {/* DATOS EXTRAS */}
                  <View
                    style={{
                      width: "70%",
                      border: "1px solid gray",
                      borderRadius: "5px",
                      fontSize: "7px",
                    }}
                  >
                    {/* TABLE HEAD */}
                    <View
                      style={{
                        flexDirection: "row",
                        backgroundColor: color,
                        borderTopRightRadius: "4px",
                        borderTopLeftRadius: "4px",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <View
                        style={{
                          fontWeight: "800",
                          textTransform: "uppercase",
                          color: "white",
                          padding: "3px",
                        }}
                      >
                        <Text>DATOS EXTRAS</Text>
                      </View>
                    </View>
                    {/* TABLE BODY */}
                    <View
                      style={{
                        flexDirection: "row",
                        borderBottom: "1px solid gray",

                        color: "#40403E",
                        display: "flex",
                        alignContent: "flex-start",
                      }}
                      minPresenceAhead={100} // Establece un umbral mínimo para evitar que se recorte
                      wrap={false}
                    >
                      <View
                        style={{
                          whiteSpace: "nowrap",
                          padding: "3px",
                          alignContent: "flex-start",
                        }}
                      >
                        <Text style={{}}>
                          TRANSPORTISTA:{" "}
                          {datosSeleccionados.manifiesto_general.transportista}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        borderBottom: "1px solid gray",
                        color: "#40403E",
                        display: "flex",
                        alignContent: "flex-start",
                      }}
                      minPresenceAhead={100} // Establece un umbral mínimo para evitar que se recorte
                      wrap={false}
                    >
                      <View
                        style={{
                          whiteSpace: "nowrap",
                          padding: "3px",
                          alignContent: "flex-start",
                        }}
                      >
                        <Text style={{}}>
                          CONDUCTOR:{" "}
                          {datosSeleccionados.manifiesto_general.conductor}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        borderBottom: "1px solid gray",
                        color: "#40403E",
                        display: "flex",
                        alignContent: "flex-start",
                      }}
                      minPresenceAhead={100} // Establece un umbral mínimo para evitar que se recorte
                      wrap={false}
                    >
                      <View
                        style={{
                          whiteSpace: "nowrap",
                          padding: "3px",
                          alignContent: "flex-start",
                        }}
                      >
                        <Text style={{}}>
                          AUXILIAR:{" "}
                          {datosSeleccionados.manifiesto_general.auxiliar}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        color: "#40403E",
                        display: "flex",
                        alignContent: "flex-start",
                      }}
                      minPresenceAhead={100} // Establece un umbral mínimo para evitar que se recorte
                      wrap={false}
                    >
                      <View
                        style={{
                          whiteSpace: "nowrap",
                          padding: "3px",
                          alignContent: "flex-start",
                        }}
                      >
                        <Text style={{}}>
                          VEHICULO:{" "}
                          {
                            datosSeleccionados.manifiesto_general
                              .vehiculo_asignado
                          }{" "}
                          -{" "}
                          {datosSeleccionados.manifiesto_general.placa_vehiculo}
                        </Text>
                      </View>
                    </View>
                  </View>
                  {/* CALCULOS */}
                  <View
                    style={{
                      width: "30%",
                      border: "1px solid gray",
                      borderRadius: "5px",
                      fontSize: "7px",
                    }}
                  >
                    {/* TABLE HEAD */}
                    <View
                      style={{
                        flexDirection: "row",
                        backgroundColor: color,
                        borderTopRightRadius: "4px",
                        borderTopLeftRadius: "4px",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <View
                        style={{
                          fontWeight: "800",
                          textTransform: "uppercase",
                          color: "white",
                          padding: "3px",
                        }}
                      >
                        <Text>CALCULOS</Text>
                      </View>
                    </View>
                    {/* TABLE BODY */}
                    <View
                      style={{
                        flexDirection: "row",
                        borderBottom: "1px solid gray",
                        color: "#40403E",
                        display: "flex",
                        alignContent: "flex-start",
                      }}
                      minPresenceAhead={100} // Establece un umbral mínimo para evitar que se recorte
                      wrap={false}
                    >
                      <View
                        style={{
                          whiteSpace: "nowrap",
                          padding: "3px",
                          alignContent: "flex-start",
                        }}
                      >
                        <Text style={{}}>TOTAL DE PIEZAS: {totalPiezas}</Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        borderBottom: "1px solid gray",
                        color: "#40403E",
                        display: "flex",
                        alignContent: "flex-start",
                      }}
                      minPresenceAhead={100} // Establece un umbral mínimo para evitar que se recorte
                      wrap={false}
                    >
                      <View
                        style={{
                          whiteSpace: "nowrap",
                          padding: "3px",
                          alignContent: "flex-start",
                        }}
                      >
                        <Text style={{}}>TOTAL PESO: {totalPeso}</Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        borderBottom: "1px solid gray",
                        color: "#40403E",
                        display: "flex",
                        alignContent: "flex-start",
                      }}
                      minPresenceAhead={100} // Establece un umbral mínimo para evitar que se recorte
                      wrap={false}
                    >
                      <View
                        style={{
                          whiteSpace: "nowrap",
                          padding: "3px",
                          alignContent: "flex-start",
                        }}
                      >
                        <Text style={{}}>
                          TOTAL P. VOL.: {totalPesoVolumen}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",

                        color: "#40403E",
                        display: "flex",
                        alignContent: "flex-start",
                      }}
                      minPresenceAhead={100} // Establece un umbral mínimo para evitar que se recorte
                      wrap={false}
                    >
                      <View
                        style={{
                          whiteSpace: "nowrap",
                          padding: "3px",
                          alignContent: "flex-start",
                        }}
                      >
                        <Text style={{}}>
                          TOTAL BULTOS:{" "}
                          {datosSeleccionados.manifiesto_general
                            .cantidad_bultos_despacho || 0}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                {/* DOCS. CONDUCTORES */}
                <View
                  style={{
                    width: "100%",
                    fontSize: "8px",
                    flexDirection: "row",
                    columnGap: "10px",
                  }}
                >
                  {/* DATOS EXTRAS */}
                  <View
                    style={{
                      width: "284.5px",
                      borderTop: "1px solid gray",
                      borderRight: "1px solid gray",
                      borderLeft: "1px solid gray",
                      borderBottom: "1px solid gray",
                      borderRadius: "5px",
                      fontSize: "7px",
                    }}
                  >
                    {/* TABLE HEAD */}
                    <View
                      style={{
                        flexDirection: "row",
                        backgroundColor: color,
                        borderTopRightRadius: "4px",
                        borderTopLeftRadius: "4px",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      <View
                        style={{
                          fontWeight: "800",
                          textTransform: "uppercase",
                          color: "white",
                          padding: "3px",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        <Text>MANIFIESTA FUE GESTIONADO Y FINALIZADO </Text>
                        <Text>
                          {datosSeleccionados.manifiesto_general.hora_creado}{" "}
                        </Text>
                      </View>
                    </View>
                    {/* TABLE BODY */}
                    <View
                      style={{
                        flexDirection: "row",
                        color: "#40403E",
                        display: "flex",
                        alignContent: "flex-start",
                      }}
                      minPresenceAhead={100} // Establece un umbral mínimo para evitar que se recorte
                      wrap={false}
                    >
                      <View
                        style={{
                          whiteSpace: "nowrap",
                          padding: "3px",
                          alignContent: "flex-start",
                        }}
                      >
                        <Text style={{}}>
                          USUARIO:{" "}
                          {
                            datosSeleccionados.manifiesto_general
                              .colaborador_usuario
                          }
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              {/* OPERACIONES  */}
              <View
                style={{
                  width: "25%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></View>
            </View>
          </View>
        </View>
        {/* </Paginacion> */}
        <Text
          style={styles.footerpage}
          render={({ pageNumber, totalPages }) =>
            `Página ${pageNumber} de ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
    //
  );
};
export default DocuPDFDespacho;
